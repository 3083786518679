import React, { useContext, useEffect, useState } from "react";
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Checkbox, DataTable } from "react-native-paper";
import { AntDesign, Feather, FontAwesome } from "@expo/vector-icons";
import DeviceOrientationContext from "../../components/context/DeviceOrientationContext";
import { useIsFocused } from "@react-navigation/native";
import UpdateConfirmationScreen from "./partials/UpdateConfirmationScreen";
import { useToast } from "react-native-toast-notifications";
import { useSelector } from "react-redux";
import {
  deleteOperationById,
  manageOperation,
} from "../../services/operationService";
import { processAttachments } from "../../services/s3BucketService";
import FilePreview from "./utils/FilePreview";
import OperationsOrderPDF from "./utils/OperationsOrderPDF";
import OrderActionComponent from "./partials/OrderActionComponent ";
import Spinner from "../../components/shared/Spinner";

interface Props {
  navigation: any;
  route: {
    params: {
      action: string;
    };
  };
}

const SingleOperation: React.FC<Props> = ({ navigation, route }) => {
  const toast = useToast();
  const { isMediumDevice, isMobileDevice, isSmallDevice } = useContext(
    DeviceOrientationContext
  );
  const isFocused = useIsFocused();
  const [title, setTitle] = useState("");
  const [action, setAction] = useState("");
  const [confirmationTextCS, setConfirmationTextCS] = useState("");
  const [successfulTextCS, setSuccessfulTextCS] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [approved, setApproved] = useState(false);
  const { shippingMethodList, shipToList } = useSelector(
    (state: any) => state.operations
  );
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPieces, setTotalPieces] = useState(0);
  const [totalBoxes, setTotalBoxes] = useState(0);
  const { stockReportList } = useSelector((state: any) => state.reports);
  let { order } = route.params;
  const [selectedOrder, setSelectedOrder] = useState(order || null);
  const shippingMethodItem = shippingMethodList.find(
    (sm: any) => sm.value == order.shippingMethod
  );
  const [sapDocNumber, setSapDocNumber] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (order._id == undefined) {
      setTimeout(() => {
        navigation.navigate("OperationsManagement");
      }, 1500);
    } else {
      setSelectedOrder(order);
      initializeProducts(order);
      calculateTotals(order);
    }
  }, [order]);

  useEffect(() => {
    if (isFocused) {
      switch (route.params.action) {
        case "edit":
          setTitle("Update Order");
          setApproved(true);
          break;
        case "view":
          setTitle("View Order");
          setApproved(true);
          break;
        case "requestHelp":
          setTitle("Request For Help");
          setApproved(false);
          break;
        case "delete":
          setTitle("Delete Order");
          setApproved(false);
          break;
        case "clone":
          setTitle("Clone Order");
          setApproved(false);
          break;
        default:
          break;
      }
      setAction(route.params.action);
      setLoaded(true);
    } else setLoaded(false);
  }, [isFocused]);

  useEffect(() => {
    switch (action) {
      case "edit":
        setTitle("Update Order");
        break;
      case "requestHelp":
        setTitle("Request For Help");
        setConfirmationTextCS("Are you sure you want to process the order?");
        break;
      case "delete":
        setTitle("Cancelled Order");
        setConfirmationTextCS("Request to cancel the Order");
        break;
      case "clone":
        setTitle("Clone Order");
        setConfirmationTextCS("Are you sure you want to clone this order?");
        break;
      default:
        break;
    }
    setSuccessfulTextCS("Request Submitted");
  }, [action]);

  const cancelOperation = async () => {
    const updatedOrder = {
      ...order,
      orderStatus: "Cancelled",
    };

    await manageOperation(updatedOrder, "edit");
  };

  // Process operation based on order status
  const processOperation = async (sapDoc: string) => {
    const updatedOrder = {
      ...selectedOrder,
      orderStatus:
        selectedOrder.orderStatus === "Pending" ? "Processed" : "Completed",
      ...(selectedOrder.orderStatus === "Completed"
        ? { isApproved: true }
        : { sapDocumentNumber: sapDoc || selectedOrder.sapDocumentNumber }),
    };

    await manageOperation(updatedOrder, "edit");
  };

  // Handle input for SAP Document Number and process the order
  const handleProcessOrder = async () => {
    if (selectedOrder.orderStatus === "Pending" && !sapDocNumber) {
      toast.show("Please provide the SAP Document Number to proceed", {
        type: "warning",
      });
      return;
    }
    setLoading(true);
    await processOperation(sapDocNumber);
    setLoading(false);
    toast.show(
      selectedOrder.orderStatus === "pending"
        ? "The order has been processed and requested for approval completion"
        : "The order has been marked as completed",
      { type: "success" }
    );
    navigation.navigate("OperationsManagement");
  };

  useEffect(() => {
    if (approved) {
      switch (action) {
        case "requestHelp":
          handleProcessOrder();
          break;
        case "delete":
          cancelOperation();
          toast.show("Request for cancellation has been sent", {
            type: "danger",
          });
          break;
        case "clone":
          cloneOperation();
        default:
          break;
      }
    }
  }, [approved]);

  const approveCS = () => {
    setApproved(true);
  };

  const rejectCS = () => {
    navigation.navigate("OperationsManagement");
  };

  const updateOperation = () => {
    navigation.navigate("PlaceOrder", {
      operation: order,
      titleToDisplay: "Update Order",
      requestType: "place",
      mode: "edit",
    });
  };

  const cloneOperation = () => {
    navigation.navigate("PlaceOrder", {
      operation: order,
      titleToDisplay: "Update Order",
      requestType: "place",
      mode: "clone",
    });
  };

  const mobileDevice = isSmallDevice || isMediumDevice || isMobileDevice;

  const contentStyle = {
    width: mobileDevice ? "100%" : "60%",
    maxWidth: mobileDevice ? "100%" : "60%",
    flex: 1,
    borderWidth: !mobileDevice ? 1 : 0,
    borderColor: "#efefef",
  };

  const formatDate = (isoString: string) => {
    const date = new Date(isoString);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JavaScript
    const year = date.getFullYear();

    return `${day} / ${month} / ${year}`;
  };

  const deleteOperation = async (id: string) => {
    if (confirm("Do you want to Delete this Operation?")) {
      await deleteOperationById(id)
        .then((result) => {
          toast.show(result.data.message, {
            type: "success",
          });

          navigation.navigate("OperationsManagement");
          // backToList();
        })
        .catch((err) => {
          toast.show(err.message, {
            type: "danger",
          });
        })
        .finally(() => {
          // setDisplayLoader(!displayLoader);
        });
    }
  };

  const updateCurrentAction = (action: string) => {
    setAction((prev) => action);
  };

  const initializeProducts = (selectedOrder: any) => {
    if (selectedOrder && selectedOrder?.products?.length > 0) {
      const newProductsList = selectedOrder.products.map((product: any) => {
        // Find matching productValue
        const matchingProductValue = stockReportList.find((item: any) => {
          return (
            item.ItemCode[0].toLowerCase() === product.ItemCode.toLowerCase() &&
            item.Location.toLowerCase() === product.Location.toLowerCase()
          );
        });

        const productPrice = product.price ? parseFloat(product.price) : 1;
        const productQuantity = product.quantity
          ? parseFloat(product.quantity)
          : 0;

        // Merge product with the matching productValue, if it exists
        return {
          ...product,
          ...matchingProductValue, // Add properties from productValue
          totalPrice: productPrice * productQuantity,
          totalBoxes: product.uom === "Boxes" ? productQuantity : 0,
          totalPieces:
            product.uom === "Boxes"
              ? productQuantity * product.PerCtns
              : productQuantity,
        };
      });

      // Update selectedOrder with the new products list
      setSelectedOrder((prev: any) => ({
        ...prev, // Keep other properties of the selectedOrder
        products: newProductsList, // Update the products array
      }));
    }

    const handleAttachments = async (operation: any) => {
      // Await the result of processAttachments to get the resolved attachments array
      const attachments = await processAttachments(operation.attachments ?? []);

      // Add custom fields like fileName, fileUri, and fileType to each attachment
      const updatedAttachments = attachments.map((attachment) => {
        return {
          ...attachment,
          fileName: attachment?.fileName, // Custom field
          fileUri: attachment?.fileUri, // Custom field
          fileType: attachment?.fileType, // Custom field
        };
      });

      // Now set the value with the updated attachments that include custom fields
      setSelectedOrder((prev: any) => ({
        ...prev, // Keep other properties of the selectedOrder
        attachments: updatedAttachments, // Update the products array
      }));
    };

    // Call the function in your code
    handleAttachments(selectedOrder);
  };

  const calculateTotals = (selectedOrder: any) => {
    let totalPrice = 0;
    let totalBoxes = 0;
    let totalPieces = 0;

    // Iterate through products and calculate totals
    selectedOrder.products.forEach((product: any) => {
      totalPrice += product.price * product.quantity;

      // Check if the product has a UoM and count accordingly
      if (product.uom === "Boxes") {
        totalBoxes += product.quantity;
      } else if (product.uom === "Pieces") {
        totalPieces += product.quantity;
      }
    });
    setTotalPrice(totalPrice);
    setTotalBoxes(totalBoxes);
    setTotalPieces(totalPieces);
  };
  const DrawerHeader = () => {
    return (
      <View style={styles.drawerStyle}>
        <View style={{ flex: 1, flexDirection: "row" }}>
          <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <AntDesign name="arrowleft" size={24} color="black" />
            </TouchableOpacity>
          </View>
          <View style={{ paddingLeft: 10, justifyContent: "center" }}>
            <Text style={{ fontWeight: "500" }}>
              {title} - {selectedOrder.customerReference}
            </Text>
          </View>
        </View>
        {action == "edit" && (
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              onPress={() => updateCurrentAction("requestHelp")}
            >
              <View style={styles.headerBtnContainer}>
                <FontAwesome name="tick" size={24} color="black" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity>
              <View style={styles.headerBtnContainer}>
                {selectedOrder && (
                  <OperationsOrderPDF
                    order={selectedOrder}
                    year={new Date().getFullYear()}
                  />
                )}
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => deleteOperation(selectedOrder._id)}
            >
              <View style={styles.headerBtnContainer}>
                <AntDesign name="delete" size={24} color="black" />
              </View>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  };

  return (
    <View style={{ alignItems: "center", flex: 1, backgroundColor: "#fff" }}>
      {loading ? (
        <View
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20vh",
          }}
        >
          <Spinner size="large" color="#00ff00" />
        </View>
      ) : (
        <View style={contentStyle}>
          {!loaded ? (
            <View
              style={{
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <ActivityIndicator
                size="large"
                color="lightgrey"
                style={{ marginVertical: 5 }}
              />
            </View>
          ) : (
            <>
              {approved ? (
                <ScrollView style={[styles.container]}>
                  <DrawerHeader />
                  {/* Customer Details */}
                  <View style={styles.content}>
                    <View style={styles.formSection}>
                      <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 1 }}>
                          <Text style={styles.formHeaders}>
                            Customer Details
                          </Text>
                        </View>
                        {action == "edit" && (
                          <View style={{ flex: 1 }}>
                            <TouchableOpacity onPress={updateOperation}>
                              <Text style={{ textAlign: "right" }}>
                                <Feather name="edit" size={20} color="#000" />
                              </Text>
                            </TouchableOpacity>
                          </View>
                        )}
                      </View>
                      <View style={styles.customerRow}>
                        <View style={{ flex: 1 }}>
                          <Text style={{ fontWeight: "bold" }}>
                            Customer Name:
                          </Text>
                        </View>
                        <View style={{ flex: 2 }}>
                          <Text>{selectedOrder.customerName}</Text>
                        </View>
                        <View style={{ flex: 1 }}>
                          <Text style={{ fontWeight: "bold" }}>
                            Customer Ref#:
                          </Text>
                        </View>
                        <View style={{ flex: 2 }}>
                          <Text>{selectedOrder.customerReference}</Text>
                        </View>
                      </View>
                      <View style={styles.customerRow}>
                        <View style={{ flex: 1 }}>
                          <Text style={{ fontWeight: "bold" }}>Contact:</Text>
                        </View>
                        <View style={{ flex: 2 }}>
                          <Text>{selectedOrder.customerNumber}</Text>
                        </View>
                        <View style={{ flex: 1 }}>
                          <Text style={{ fontWeight: "bold" }}>Email:</Text>
                        </View>
                        <View style={{ flex: 2 }}>
                          <Text>{selectedOrder.customerEmail}</Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.formSection}>
                      <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 1 }}>
                          <Text style={styles.formHeaders}>
                            Shipping Details
                          </Text>
                        </View>
                        {action == "edit" && (
                          <View style={{ flex: 1 }}>
                            <TouchableOpacity onPress={updateOperation}>
                              <Text style={{ textAlign: "right" }}>
                                <Feather name="edit" size={20} color="#000" />
                              </Text>
                            </TouchableOpacity>
                          </View>
                        )}
                      </View>
                      <View>
                        <View style={styles.shippingRow}>
                          <View style={{ flex: 1 }}>
                            <Text style={{ fontWeight: "bold" }}>
                              Delivery Date:
                            </Text>
                          </View>
                          <View style={{ flex: 2 }}>
                            <Text>
                              {formatDate(selectedOrder.deliveryDate)}
                            </Text>
                          </View>
                        </View>
                        <View style={styles.shippingRow}>
                          <View style={{ flex: 1 }}>
                            <Text style={{ fontWeight: "bold" }}>
                              Shipping Method:
                            </Text>
                          </View>
                          <View style={{ flex: 2 }}>
                            <Text>
                              {shippingMethodItem
                                ? shippingMethodItem.value
                                : ""}
                            </Text>
                          </View>
                        </View>
                        <View style={styles.shippingRow}>
                          <View style={{ flex: 1 }}>
                            <Text style={{ fontWeight: "bold" }}>Address</Text>
                          </View>
                          <View style={{ flex: 2 }}>
                            <Text>{selectedOrder.address}</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[styles.formSection, { borderBottomWidth: 0 }]}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 1 }}>
                          <Text style={styles.formHeaders}>Product</Text>
                        </View>
                        {action == "edit" && (
                          <View style={{ flex: 1 }}>
                            <TouchableOpacity>
                              <Text
                                style={{ textAlign: "right" }}
                                onPress={updateOperation}
                              >
                                <Feather name="edit" size={20} color="#000" />
                              </Text>
                            </TouchableOpacity>
                          </View>
                        )}
                      </View>
                      <View style={styles.customerRow}>
                        <View style={{ flex: 1, paddingHorizontal: 5 }}>
                          <Text
                            style={{
                              textAlign: "right",
                              color: "grey",
                              fontWeight: "bold",
                            }}
                          >
                            Location
                          </Text>
                        </View>
                        <View style={{ flex: 1, paddingHorizontal: 5 }}>
                          <Text>{order ? selectedOrder.location : ""}</Text>
                        </View>
                      </View>
                      <View style={{ marginHorizontal: -15 }}>
                        <DataTable>
                          <DataTable.Header style={styles.datatableHeader}>
                            <DataTable.Title
                              style={[
                                styles.productColumn,
                                { alignItems: "center" },
                              ]}
                            >
                              <Text style={styles.datatableHeaderItemText}>
                                Product
                              </Text>
                            </DataTable.Title>
                            <DataTable.Title
                              numberOfLines={2}
                              numeric
                              style={[
                                styles.orderQtyColumn,
                                { alignItems: "center" },
                              ]}
                            >
                              <View>
                                <Text style={styles.datatableHeaderItemText}>
                                  Order
                                </Text>
                                <Text style={styles.datatableHeaderItemText}>
                                  Qty
                                </Text>
                              </View>
                            </DataTable.Title>
                            <DataTable.Title
                              style={[styles.uom, { alignItems: "center" }]}
                            >
                              <Text style={styles.datatableHeaderItemText}>
                                UoM
                              </Text>
                            </DataTable.Title>
                            <DataTable.Title
                              numeric
                              style={[styles.price, { alignItems: "center" }]}
                            >
                              <Text style={styles.datatableHeaderItemText}>
                                Price{" "}
                                <Text style={{ fontSize: 7 }}>(Ex GST)</Text>
                              </Text>
                            </DataTable.Title>
                          </DataTable.Header>
                          {selectedOrder.products &&
                            selectedOrder.products?.length > 0 && (
                              <React.Fragment>
                                {selectedOrder.products.map(
                                  (p: any, index: number) => {
                                    return (
                                      <DataTable.Row
                                        key={index}
                                        style={{ paddingVertical: 15 }}
                                      >
                                        <View style={styles.productColumn}>
                                          <View>
                                            <Text style={{ marginBottom: 5 }}>
                                              {p.name}
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 12,
                                                color: "#818181",
                                              }}
                                            >
                                              {p.id}
                                            </Text>
                                          </View>
                                        </View>
                                        <DataTable.Cell
                                          style={styles.orderQtyColumn}
                                          numeric
                                        >
                                          {p.quantity}
                                        </DataTable.Cell>
                                        <DataTable.Cell
                                          style={styles.uom}
                                          numeric
                                        >
                                          {p.uom}
                                        </DataTable.Cell>
                                        <DataTable.Cell style={styles.price}>
                                          <View>
                                            {selectedOrder?.orderType ===
                                            "Contract Order" ? (
                                              <Text>{"Contract"}</Text>
                                            ) : (
                                              <Text>{p.price}</Text>
                                            )}
                                          </View>
                                        </DataTable.Cell>
                                      </DataTable.Row>
                                    );
                                  }
                                )}
                                <DataTable.Row style={{ paddingVertical: 15 }}>
                                  <DataTable.Cell
                                    style={{
                                      flex: 1,
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                  >
                                    <Text></Text>
                                  </DataTable.Cell>

                                  <DataTable.Cell
                                    style={{
                                      flex: 1,
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                  >
                                    <Text
                                      style={{
                                        paddingLeft: 15,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Total Boxes :{" "}
                                    </Text>
                                  </DataTable.Cell>
                                  <DataTable.Cell
                                    style={{
                                      flex: 1,
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                  >
                                    <Text style={{ fontWeight: "bold" }}>
                                      {" "}
                                      {totalBoxes}{" "}
                                    </Text>
                                  </DataTable.Cell>
                                </DataTable.Row>

                                <DataTable.Row style={{ paddingVertical: 15 }}>
                                  <DataTable.Cell
                                    style={{
                                      flex: 1,
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                  >
                                    <Text></Text>
                                  </DataTable.Cell>

                                  <DataTable.Cell
                                    style={{
                                      flex: 1,
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                  >
                                    <Text
                                      style={{
                                        paddingLeft: 15,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Total Pieces :{" "}
                                    </Text>
                                  </DataTable.Cell>
                                  <DataTable.Cell
                                    style={{
                                      flex: 1,
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                  >
                                    <Text style={{ fontWeight: "bold" }}>
                                      {" "}
                                      {totalPieces}{" "}
                                    </Text>
                                  </DataTable.Cell>
                                </DataTable.Row>

                                <DataTable.Row style={{ paddingVertical: 15 }}>
                                  <DataTable.Cell
                                    style={{
                                      flex: 1,
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                  >
                                    <Text></Text>
                                  </DataTable.Cell>

                                  <DataTable.Cell
                                    style={{
                                      flex: 1,
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                  >
                                    <Text
                                      style={{
                                        paddingLeft: 15,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Total Amount :{" "}
                                    </Text>
                                  </DataTable.Cell>
                                  <DataTable.Cell
                                    style={{
                                      flex: 1,
                                      justifyContent: "flex-end",
                                    }}
                                    numeric
                                  >
                                    {selectedOrder?.orderType ===
                                    "Contract Order" ? (
                                      <Text style={{ fontWeight: "bold" }}>
                                        {" "}
                                        {"Contract"}{" "}
                                      </Text>
                                    ) : (
                                      <Text style={{ fontWeight: "bold" }}>
                                        {" "}
                                        {totalPrice}{" "}
                                      </Text>
                                    )}
                                  </DataTable.Cell>
                                </DataTable.Row>
                              </React.Fragment>
                            )}
                        </DataTable>
                      </View>
                    </View>

                    <View style={styles.formSection}>
                      <Text style={styles.formHeaders}>Uploaded Files:</Text>
                      {/* Display Existing Files */}
                      {selectedOrder &&
                      selectedOrder?.attachments?.length > 0 ? (
                        <View style={styles.fileList}>
                          {selectedOrder.attachments.map(
                            (file: any, index: number) => (
                              <FilePreview
                                key={index}
                                fileName={file.fileName}
                                fileUri={file.fileUri}
                                fileType={file.fileType}
                                styles={styles}
                                mode="preview"
                              />
                            )
                          )}
                        </View>
                      ) : (
                        <Text> No files found ... </Text>
                      )}
                    </View>

                    <View style={styles.formSection}>
                      <Text style={styles.formHeaders}>Freight Delivery</Text>
                      <View
                        style={{ alignItems: "center", paddingVertical: 8 }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            borderWidth: 1,
                            borderColor: "#000",
                            borderRadius: 3,
                          }}
                        >
                          <View
                            style={{
                              paddingVertical: 5,
                              paddingHorizontal: 10,
                              borderRightWidth: 1,
                              borderRightColor: "#000",
                            }}
                          >
                            <FontAwesome
                              name="dollar"
                              size={20}
                              color="black"
                            />
                          </View>
                          <View
                            style={{
                              paddingVertical: 5,
                              paddingHorizontal: 10,
                              width: 150,
                            }}
                          >
                            <Text>{selectedOrder.freightDelivery}</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[styles.formSection, { borderBottomWidth: 0 }]}
                    >
                      <Text style={styles.formHeaders}>Submission</Text>
                      <View style={styles.customerRow}>
                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Text style={{ fontWeight: "500" }}>Remarks</Text>
                        </View>
                        <View style={{ flex: 3 }}>
                          <Text>{selectedOrder.remarks}</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </ScrollView>
              ) : route.params.action === "requestHelp" ? (
                <ScrollView style={[styles.container]}>
                  <DrawerHeader />
                  <OrderActionComponent
                    handleProcessOrder={handleProcessOrder}
                    sapDocNumber={sapDocNumber}
                    selectedOrder={selectedOrder}
                    setSapDocNumber={setSapDocNumber}
                  />
                </ScrollView>
              ) : (
                <UpdateConfirmationScreen
                  confirmationText={confirmationTextCS}
                  successfulText={successfulTextCS}
                  approve={approveCS}
                  reject={rejectCS}
                />
              )}
            </>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
  },
  content: {
    paddingHorizontal: 15,
    paddingBottom: 25,
  },
  formHeaders: {
    color: "#B5B5B5",
    fontSize: 20,
    fontWeight: "500",
    marginBottom: 7,
  },
  formSection: {
    paddingVertical: 20,
    borderBottomWidth: 2,
    borderBottomColor: "#000",
  },
  customerRow: {
    flexDirection: "row",
    paddingVertical: 8,
  },
  shippingRow: {
    flexDirection: "row",
    paddingVertical: 5,
  },
  checkbox: {
    paddingLeft: 0,
    marginLeft: -7,
    paddingVertical: 0,
  },
  checkboxLabel: {
    marginTop: 2,
    textAlign: "left",
    justifyContent: "center",
    fontWeight: "500",
  },
  datatableHeader: {
    height: 45,
    backgroundColor: "#D9D9D9",
  },
  productColumn: {
    flex: 2.75,
    paddingVertical: 0,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  orderQtyColumn: {
    flex: 0.75,
    paddingVertical: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  uom: {
    flex: 1.1,
    justifyContent: "center",
    paddingVertical: 0,
    alignItems: "center",
  },
  inventory: {
    flex: 1.1,
    justifyContent: "center",
    paddingVertical: 0,
    alignItems: "center",
  },
  price: {
    flex: 1.2,
    justifyContent: "flex-end",
    paddingVertical: 0,
    alignItems: "center",
  },
  remarksInput: {
    borderWidth: 1,
    borderColor: "#000",
    height: 60,
    padding: 4,
  },
  datatableHeaderItemText: {
    fontSize: 12,
    textAlign: "center",
    lineHeight: 16,
    color: "#000",
  },
  headerBtnContainer: {
    padding: 5,
    marginHorizontal: 3,
  },
  fileList: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    marginTop: 20,
  },
  filePreviewSection: {
    // width: "40%", // Adjusted for a smaller layout
    // aspectRatio: 1.2, // More compact aspect ratio
    // marginBottom: 15,
    // borderRadius: 8,
    // backgroundColor: "#ffffff",
    // shadowColor: "#000",
    // shadowOpacity: 0.1,
    // shadowRadius: 6,
    // elevation: 2,
    // borderWidth: 1,
    // borderColor: "#ddd",
    // overflow: "hidden",
  },
  filePreviewHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 8,
    paddingVertical: 5,
    backgroundColor: "#f8f8f8",
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
  },
  fileNameText: {
    fontSize: 12, // Reduced font size
    fontWeight: "400",
    color: "#333",
    flexWrap: "wrap",
    maxWidth: "80%", // Prevents text overflow
  },
  fileLink: {
    fontSize: 12, // Adjusted font size
    color: "#007bff",
    textDecorationLine: "underline",
    textAlign: "center",
    marginTop: 5,
  },
  previewContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  imagePreview: {
    width: "100%",
    height: 80, // Reduced height for image preview
    backgroundColor: "#f5f5f5",
    borderTopWidth: 1,
    borderTopColor: "#eee",
  },
  cardImage: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
  },
  placeholderIconContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e0e0e0",
    width: "100%",
    height: 80, // Same height for placeholder as the image preview
  },
  pdfLink: {
    fontSize: 12,
    color: "#007aff",
    textDecorationLine: "underline",
    textAlign: "center",
    marginVertical: 3,
  },
  drawerStyle: {
    flexDirection: "row",
    borderBottomWidth: 2,
    borderColor: "grey",
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 999,
    backgroundColor: "#fff",
    height: 40,
  },
});

export default SingleOperation;
