import React, { useState } from "react";
import {
  ActivityIndicator,
  Picker,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Controller } from "react-hook-form";
import { DataTable } from "react-native-paper";
import { AntDesign, FontAwesome } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import FileUploader from "../utils/FileUploader";
import LocationAutoComplete from "./LocationAutoComplete";
import OrderScreenSecondProductModal from "./OrderScreenSecondProductModal";

interface ValidationRules {
  [key: string]: {
    required?: string;
    minLength?: { value: number; message: string };
    maxLength?: { value: number; message: string };
    pattern?: { value: RegExp; message: string };
    validate?: (value: any) => string | boolean;
  };
}

interface Props {
  navigateBack: () => void;
  navigateForward: () => void;
  mobileDevice: boolean;
  mode: any;
  form: any;
  validationRules: ValidationRules;
}

const optionsPerPage = [2, 3, 4];

const OrderScreenSecond: React.FC<Props> = ({
  navigateBack,
  navigateForward,
  mobileDevice,
  mode,
  form,
  validationRules,
}) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = form;
  const [isLoading, setLoading] = useState<boolean>(true);
  const [quantityErrorMessage, setQuantityErrorMessage] = useState<
    string | null
  >(null);
  const [page, setPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState(optionsPerPage[0]);
  const [isFreightDelivery, setIsFreightDelivery] = useState(false);
  const [productLoader, setProductLoader] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isDesktopSearchVisible, setIsDesktopSearchVisible] = useState(false);
  const [isFilteredProductListVisible, setIsFilteredProductListVisible] =
    useState(false);
  const [productsData, setProductsData] = useState(getValues("products") || []);
  const [existingProductData, setExistingProductData] = useState(null);
  const [selectedProductIndex, setSelectedProductIndex] = useState<
    number | null
  >(null);

  const showProductList = () => {
    setIsFilteredProductListVisible(true);
  };

  const hideProductList = () => {
    setTimeout(() => {
      setIsFilteredProductListVisible(false);
    }, 250);
  };

  console.log(getValues("products"), "products");
  console.log(existingProductData, "existingProductData");
  const addProduct = (product: any) => {
    setProductLoader(true);

    // Get existing products
    const oldData = getValues("products") || [];
    const isFirstProduct = oldData.length === 0;

    // Determine default UoM and quantity based on conditions
    const defaultUom = isFirstProduct ? "Boxes" : product.uom || "Pieces";
    const defaultQuantity =
      defaultUom === "Boxes"
        ? Math.floor(product.OnHand / product.PerCtns) || 1 // Ensure quantity is an integer
        : product.OnHand || 1;

    const newProduct = {
      ...product,
      quantity: product.quantity || defaultQuantity, // Use default quantity
      price: product.price || 1, // Default price if not provided
      uom: defaultUom, // Default or user-selected UoM
      totalPrice: 0,
      totalBoxes: 0,
      totalPieces: 0,
    };

    // Add the new product to the list
    const newData = [...oldData, newProduct];
    setValue("products", newData);
    setProductsData(newData);

    // Reset search fields and trigger recalculation
    setSearchText("");
    setFilteredData([]);
    setIsDesktopSearchVisible(false);
    calculateTotals();
    setProductLoader(false);
  };

  const removeProduct = (index: number) => {
    setProductLoader(true);

    const oldData = getValues("products");
    if (index < 0 || index >= oldData.length) {
      console.error("Invalid index provided");
      setProductLoader(false);
      return;
    }

    // Create a new array excluding the product at the specified index
    const updatedProducts = oldData.filter((_, i) => i !== index);

    // Update values and state
    setValue("products", updatedProducts);
    setProductsData(updatedProducts);

    // Calculate totals and stop loader
    calculateTotals();
    setProductLoader(false);
  };

  const editProduct = (index?: number) => {
    setProductLoader(true);
    if (index === undefined || index < 0) {
      console.error("Invalid index provided");
      return;
    }

    const oldData = getValues("products");
    if (!Array.isArray(oldData) || index >= oldData.length) {
      console.error("Index is out of bounds or products data is invalid");
      return;
    }

    setProductLoader(true);
    const selectedEditProduct = oldData[index]; // Access element directly
    setSelectedProductIndex(index);
    setExistingProductData(selectedEditProduct);
    setIsDesktopSearchVisible(true);
    setProductLoader(false);
  };

  const calculateTotals = () => {
    setSelectedProductIndex(null);
    setExistingProductData(null);
    const productList = getValues("products"); // Fetch the current product list
    let totalPrice = 0;
    let totalBoxes = 0;
    let totalPieces = 0;

    const updatedProductList = productList.map((product: any) => {
      const productPrice = product.price ? parseFloat(product.price) : 1;
      const productQuantity = product.quantity
        ? parseFloat(product.quantity)
        : 0;

      if (product.uom === "Boxes") {
        // Calculate total quantity for Boxes
        const qtyInPieces = productQuantity * product.PerCtns;
        totalBoxes += productQuantity;
        totalPieces += qtyInPieces; // Convert boxes to pieces and add to total
        totalPrice += productPrice * productQuantity;
      } else if (product.uom === "Pieces" || product.uom === "Piece") {
        // Calculate total quantity for Pieces
        totalPieces += productQuantity;
        totalPrice += productPrice * productQuantity;
      }
      // Return the updated product with individual totals
      return {
        ...product,
        totalPrice: productPrice * productQuantity,
        totalBoxes: product.uom === "Boxes" ? productQuantity : 0,
        totalPieces:
          product.uom === "Boxes"
            ? productQuantity * product.PerCtns
            : productQuantity,
      };
    });

    // Update the form values with the modified product list
    setProductsData(updatedProductList);
    setValue("products", updatedProductList);
  };

  const { stockReportList } = useSelector((state: any) => state.reports);

  const showFreightDelivery = () => {
    setIsFreightDelivery(true);
  };

  const showDesktopSearch = () => {
    setIsDesktopSearchVisible(true);
  };

  const handleSearch = (text: string) => {
    setSearchText(text);
    const newData = stockReportList.filter((item: any) => {
      return item.ItemName[0].toLowerCase().includes(text.toLowerCase());
    });

    const updatedData = newData.map(({ ItemName, ...rest }) => ({
      ...rest,
      name: ItemName[0],
    }));
    setFilteredData(updatedData);
  };

  const renderItem = ({ item }: any) => (
    <TouchableOpacity style={styles.item} onPress={() => addProduct(item)}>
      <Text>{item.name}</Text>
    </TouchableOpacity>
  );

  const DataTableHeader = () => {
    return (
      <DataTable.Header style={styles.datatableHeader}>
        <DataTable.Title
          numberOfLines={2}
          numeric
          style={[styles.orderQtyColumn, { alignItems: "center" }]}
        >
          <View>
            <Text style={styles.datatableHeaderItemText}>Order</Text>
            <Text style={styles.datatableHeaderItemText}>Qty</Text>
          </View>
        </DataTable.Title>

        {/* UOM Column */}
        <DataTable.Title style={[styles.uom, { alignItems: "center" }]}>
          <Text style={styles.datatableHeaderItemText}>UoM</Text>
        </DataTable.Title>

        {/* Inventory Column */}
        <DataTable.Title
          numeric
          style={[styles.inventory, { alignItems: "center" }]}
        >
          <Text style={styles.datatableHeaderItemText}>Inventory</Text>
        </DataTable.Title>

        {/* Price Column */}
        <DataTable.Title
          numeric
          style={[styles.price, { alignItems: "center" }]}
        >
          {mobileDevice ? (
            <>
              <Text style={styles.datatableHeaderItemText}>Price</Text>
              <Text style={{ fontSize: 7, color: "#888" }}>(Ex GST)</Text>
            </>
          ) : (
            <Text style={styles.datatableHeaderItemText}>
              Price <Text style={{ fontSize: 7, color: "#888" }}>(Ex GST)</Text>
            </Text>
          )}
        </DataTable.Title>

        {/* Empty Column for spacing */}
        <DataTable.Title style={{ maxWidth: 30 }}>
          <Text></Text>
        </DataTable.Title>
      </DataTable.Header>
    );
  };

  const DataTableRows = () => {
    return (
      <React.Fragment>
        {productsData?.map((p: any, index: number) => {
          return (
            <View key={index} style={styles.rowWrapper}>
              {/* Product Name Row */}
              <DataTable.Row
                style={{
                  paddingVertical: 10,
                  borderBottomWidth: 1,
                  borderBottomColor: "#eee",
                }}
              >
                <View style={{ flexGrow: 1, width: "100%" }}>
                  <Text style={styles.productNameText}>{p.name}</Text>
                  <Text style={styles.productIdText}>{p.ItemCode}</Text>
                </View>
              </DataTable.Row>

              {/* Input Fields Row */}
              <DataTable.Row style={{ paddingVertical: 10 }}>
                {/* Quantity */}
                <DataTable.Cell style={styles.orderQtyColumnNew} numeric>
                  <Controller
                    control={control}
                    name={`products[${index}].quantity`}
                    defaultValue={p.quantity || 1}
                    render={({ field: { onChange, value } }) => {
                      const handleInputChange = (text: string) => {
                        const numericValue = parseInt(text) || 0;

                        if (p.uom === "Pieces" && numericValue > p.OnHand) {
                          setQuantityErrorMessage(
                            `Order quantity (${numericValue}) cannot exceed inventory (${p.OnHand}).`
                          );
                        } else if (
                          p.uom === "Boxes" &&
                          numericValue > p.OnHand / p.PerCtns
                        ) {
                          setQuantityErrorMessage(
                            `Order quantity (${numericValue}) cannot exceed inventory in boxes.`
                          );
                        } else {
                          setQuantityErrorMessage(null);
                          onChange(numericValue);
                        }
                      };

                      return (
                        <TextInput
                          style={[
                            styles.quantityPriceInputsNew,
                            quantityErrorMessage
                              ? {
                                  borderColor: "#d9534f",
                                  borderWidth: 1,
                                }
                              : {},
                          ]}
                          placeholder="Enter Quantity"
                          value={value?.toString() || "1"}
                          onChangeText={handleInputChange}
                          onBlur={() => calculateTotals()}
                          keyboardType="numeric"
                        />
                      );
                    }}
                  />
                </DataTable.Cell>

                {/* UoM Dropdown */}
                <DataTable.Cell style={styles.uomNew} numeric>
                  <Controller
                    control={control}
                    name={`products[${index}].uom`}
                    defaultValue={p.uom || "Pieces"}
                    render={({ field: { onChange, value } }) => (
                      <Picker
                        selectedValue={value}
                        style={styles.quantityPriceInputsNew}
                        onValueChange={(val) => {
                          onChange(val);
                          calculateTotals();
                        }}
                      >
                        <Picker.Item label="Boxes" value="Boxes" />
                        <Picker.Item label="Pieces" value="Pieces" />
                      </Picker>
                    )}
                  />
                </DataTable.Cell>

                {/* Inventory */}
                <DataTable.Cell style={[styles.inventoryNew]} numeric>
                  <Controller
                    control={control}
                    name={`products[${index}].inventory`}
                    defaultValue={
                      p.uom === "Boxes"
                        ? (p.OnHand / p.PerCtns).toFixed(2)
                        : p.OnHand.toString()
                    }
                    render={({ field: { value } }) => (
                      <TextInput
                        style={[
                          styles.quantityPriceInputsNew,
                          { backgroundColor: "#f7f7f7" },
                        ]}
                        placeholder="Inventory"
                        value={
                          p.uom === "Boxes"
                            ? (p.OnHand / p.PerCtns).toFixed(2)
                            : p.OnHand.toString()
                        }
                        keyboardType="numeric"
                        editable={false}
                      />
                    )}
                  />
                </DataTable.Cell>

                {/* Price */}
                <DataTable.Cell style={styles.priceNew} numeric>
                  {getValues("orderType") === "contract_order" ? (
                    <View>
                      <Text>Price</Text>
                      <Text>Contract</Text>
                    </View>
                  ) : (
                    <Controller
                      control={control}
                      name={`products[${index}].price`}
                      defaultValue={p.price || 1}
                      render={({ field: { onChange, value } }) => (
                        <TextInput
                          style={styles.quantityPriceInputsNew}
                          placeholder="Enter Price"
                          value={value?.toString() || "1"}
                          onChangeText={(text) => {
                            onChange(text);
                            calculateTotals();
                          }}
                          keyboardType="numeric"
                        />
                      )}
                    />
                  )}
                </DataTable.Cell>

                {/* Edit Button */}
                <DataTable.Cell style={{ maxWidth: 30, alignItems: "center" }}>
                  <TouchableOpacity onPress={() => editProduct(index)}>
                    <AntDesign name="edit" size={24} color="#d9534f" />
                  </TouchableOpacity>
                </DataTable.Cell>
              </DataTable.Row>

              {/* Totals Row */}
              <DataTable.Row
                style={{
                  paddingVertical: 12,
                  backgroundColor: "#F9F9F9", // Light gray for alternating row background
                  borderBottomWidth: 1,
                  borderBottomColor: "#E0E0E0", // Divider for clean separation
                  flexDirection: "row", // Flex layout
                  justifyContent: "space-between", // Distribute the items evenly
                  alignItems: "center", // Align items centrally
                }}
              >
                {/* Total Label */}
                <View
                  style={{
                    flex: 1.2,
                    justifyContent: "center",
                    paddingHorizontal: 5,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      color: "#007BFF", // Blue to stand out
                      fontSize: 14, // Adjusted for smaller devices
                      textAlign: "left",
                    }}
                  >
                    Total
                  </Text>
                </View>

                {/* Boxes Count */}
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    paddingHorizontal: 5,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#555", // Softer color for subtext
                      fontWeight: "600", // Semi-bold to distinguish from main total
                      textAlign: "center",
                    }}
                  >
                    📦 Boxes: {p.totalBoxes}
                  </Text>
                </View>

                {/* Pieces Count */}
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    paddingHorizontal: 5,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#555", // Softer color for subtext
                      fontWeight: "600", // Semi-bold for clarity
                      textAlign: "center",
                    }}
                  >
                    🧩 Pieces: {p.totalPieces}
                  </Text>
                </View>

                {/* Total Price */}
                <View
                  style={{
                    flex: 1.2,
                    justifyContent: "center",
                    paddingHorizontal: 5,
                    alignItems: "flex-end", // Right-align the total price
                  }}
                >
                  {getValues("orderType") === "contract_order" ? (
                    <Text
                      style={{
                        fontWeight: "bold",
                        color: "#28a745", // Green to stand out for price
                        fontSize: 16, // Larger for emphasis
                        textAlign: "right", // Align to the right for a clean finish
                      }}
                    >
                      Contract
                    </Text>
                  ) : (
                    <Text
                      style={{
                        fontWeight: "bold",
                        color: "#28a745", // Green to stand out for price
                        fontSize: 16, // Larger for emphasis
                        textAlign: "right", // Align to the right for a clean finish
                      }}
                    >
                      💲{p.totalPrice}
                    </Text>
                  )}
                </View>
              </DataTable.Row>
            </View>
          );
        })}
      </React.Fragment>
    );
  };

  React.useEffect(() => {
    setPage(0);
    calculateTotals();
  }, [itemsPerPage]);

  return (
    <View style={[styles.container, { flex: 1 }]}>
      {/* Customer Details */}
      <View style={{ flex: 1 }}>
        <View style={[styles.formSection]}>
          <Text style={styles.formHeaders}>Product</Text>
          <View style={{ paddingHorizontal: 20 }}>
            <>
              <Controller
                control={control}
                rules={{ ...validationRules.location }}
                name="location"
                render={({ field: { onChange, value } }) => (
                  <>
                    <LocationAutoComplete
                      onLocationSelect={(selectedLocation) =>
                        onChange(selectedLocation)
                      }
                      selectedLocation={value}
                      isLoading={isLoading}
                      setLoading={setLoading}
                    />
                  </>
                )}
              />
              {errors.location && (
                <Text style={styles.errorStyle}>{errors.location.message}</Text>
              )}
            </>
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <View style={[styles.formSection, { position: "relative" }]}>
            {productLoader && (
              <View style={styles.activityLoaderView}>
                <ActivityIndicator color="lightgrey" />
              </View>
            )}
            <View style={{ marginHorizontal: -15, marginBottom: 40 }}>
              <DataTable>
                <DataTableHeader />
                {/* SearchBar */}
                {isDesktopSearchVisible && (
                  <OrderScreenSecondProductModal
                    modalVisible={isDesktopSearchVisible}
                    setModalVisible={setIsDesktopSearchVisible}
                    hideProductList={hideProductList}
                    showProductList={showProductList}
                    handleSearch={handleSearch}
                    setSearchText={setSearchText}
                    searchText={searchText}
                    isFilteredProductListVisible={isFilteredProductListVisible}
                    filteredData={filteredData}
                    renderItem={renderItem}
                    mode={mode}
                    form={form}
                    setFilteredData={setFilteredData}
                    totalsCalculation={calculateTotals}
                    removeProduct={removeProduct}
                    existingProductData={existingProductData}
                    selectedProductIndex={selectedProductIndex}
                  />
                )}
                {getValues("products").length > 0 && (
                  <DataTableRows key={getValues("products").length} />
                )}
              </DataTable>
            </View>
            {errors.products && (
              <Text style={styles.errorStyle}>{errors.products.message}</Text>
            )}
            {quantityErrorMessage && (
              <Text style={styles.errorStyle}>{quantityErrorMessage}</Text>
            )}
            <TouchableOpacity onPress={showDesktopSearch}>
              <Text
                style={{ color: "#1E83E0", fontSize: 16, textAlign: "center" }}
              >
                {!isLoading && "Add Item +"}
              </Text>
            </TouchableOpacity>
          </View>

          <View style={styles.container}>
            <FileUploader
              styles={styles}
              setValue={setValue}
              getValues={getValues}
            />
          </View>

          <View style={[styles.formSection]}>
            <Text style={styles.formHeaders}>Frieght Delivery</Text>
            <View style={{ alignItems: "center" }}>
              <View
                style={{
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: "#000",
                  borderRadius: 3,
                }}
              >
                <View
                  style={{
                    paddingVertical: 5,
                    paddingHorizontal: 10,
                    borderRightWidth: 1,
                    borderRightColor: "#000",
                  }}
                >
                  <FontAwesome name="dollar" size={20} color="black" />
                </View>
                <Controller
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    min: { value: 1, message: "Value must be at least 1" },
                    ...validationRules.freightDelivery,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextInput
                      onChangeText={onChange}
                      onBlur={onBlur}
                      value={value}
                      placeholder="Enter Product"
                      style={{ paddingVertical: 5, paddingHorizontal: 10 }}
                    />
                  )}
                  name="freightDelivery"
                  defaultValue=""
                />
                {errors.freightDelivery && (
                  <Text style={styles.errorStyle}>
                    {errors.freightDelivery.message}
                  </Text>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingTop: !mobileDevice ? 40 : 0,
          }}
        >
          <View>
            <TouchableOpacity
              onPress={navigateBack}
              style={{
                paddingHorizontal: 25,
                paddingVertical: 12,
                backgroundColor: "#000",
                borderRadius: 30,
              }}
            >
              <Text style={{ color: "#fff" }}>Back</Text>
            </TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity
              onPress={navigateForward}
              style={{
                paddingHorizontal: 25,
                paddingVertical: 12,
                backgroundColor: "#2CA02C",
                borderRadius: 30,
              }}
            >
              <Text style={{ color: "#fff" }}>Confirm</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tab: {
    flexDirection: "row",
    alignItems: "flex-start",
    flex: 1,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rectangle: {
    flex: 1,
    height: 42,
    alignContent: "center",
    justifyContent: "center",
  },
  triangle: {
    width: 0,
    height: 0,
    borderTopWidth: 21,
    borderBottomWidth: 21,
    borderLeftWidth: 40,
    borderTopColor: "transparent",
    borderBottomColor: "transparent",
  },
  rectangle1_color: {
    backgroundColor: "#06CF1A",
  },
  rectangle2_color: {
    backgroundColor: "#D1D1D1",
  },
  rectangle3_color: {
    backgroundColor: "#EAE8E8",
  },
  triangle1_color: {
    borderLeftColor: "#06CF1A",
  },
  triangle2_color: {
    borderLeftColor: "#D1D1D1",
  },
  triangle3_color: {
    borderLeftColor: "#EAE8E8",
  },
  tabText: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "600",
  },
  container: {
    backgroundColor: "#fff",
    paddingHorizontal: 15,
    paddingTop: 25,
    paddingBottom: 20,
  },
  formHeaders: {
    color: "#B5B5B5",
    fontSize: 20,
    fontWeight: "500",
    marginBottom: 15,
  },
  formSection: {
    marginBottom: 40,
  },
  checkbox: {
    paddingLeft: 0,
    marginLeft: -7,
    paddingVertical: 0,
  },
  checkboxLabel: {
    marginTop: 2,
    textAlign: "left",
    justifyContent: "center",
    fontWeight: "500",
  },
  datatableHeaderItemText: {
    fontSize: 12,
    textAlign: "center",
    lineHeight: 16,
    color: "#000",
  },
  datatableHeader: {
    height: 45,
    backgroundColor: "#D9D9D9",
  },
  productColumn: {
    flex: 1.1,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  orderQtyColumn: {
    flex: 0.75,
    justifyContent: "center",
    alignItems: "center",
  },
  uom: {
    flex: 1.1,
    justifyContent: "center",
    alignItems: "center",
  },
  inventory: {
    flex: 1.1,
    justifyContent: "center",
    alignItems: "center",
  },
  price: {
    flex: 1.1,
    justifyContent: "center",
    alignItems: "center",
  },
  customerRow: {
    flexDirection: "row",
    paddingVertical: 8,
  },
  searchInputContainer: {
    flex: 1,
    backgroundColor: "#F3F3F3",
    flexDirection: "row",
    borderRadius: 30,
    marginVertical: 15,
  },
  searchInput: {
    flex: 1,
    paddingVertical: 13,
    paddingHorizontal: 15,
    borderWidth: 0,
  },
  searchButton: {
    paddingVertical: 13,
    paddingHorizontal: 15,
    justifyContent: "center",
  },
  item: {
    padding: 10,
    borderBottomColor: "#ccc",
  },
  list: {
    borderWidth: 1,
    borderColor: "#000",
  },
  quantityPriceInputs: {
    paddingVertical: 15,
    paddingHorizontal: 15,
    borderWidth: 1,
    borderColor: "#79747E",
    width: "100%",
    maxWidth: 100,
    textAlign: "center",
    borderRadius: 3,
  },
  navigationBtns: {
    paddingHorizontal: 25,
    paddingVertical: 12,
    backgroundColor: "#D9D9D9",
    borderRadius: 30,
  },
  activityLoaderView: {
    position: "absolute",
    zIndex: 999,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fffefe7a",
  },
  errorStyle: {
    marginBottom: 10,
    fontSize: 13,
    color: "red",
  },
  uploadButton: {
    backgroundColor: "#1E83E0",
    paddingVertical: 10,
    borderRadius: 5,
  },
  uploadButtonText: {
    color: "white",
    fontSize: 16,
    textAlign: "center",
  },
  rowWrapper: {
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  productColumnNew: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingVertical: 5,
  },
  productNameText: {
    fontSize: 16,
    fontWeight: "bold",
    flexWrap: "wrap",
    width: "100%",
  },
  productIdText: {
    fontSize: 12,
    color: "#818181",
    width: "100%",
  },
  orderQtyColumnNew: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  uomNew: {
    flex: 1.1,
    justifyContent: "center",
    alignItems: "center",
  },
  inventoryNew: {
    flex: 1.1,
    justifyContent: "center",
    alignItems: "center",
  },
  priceNew: {
    flex: 1.1,
    justifyContent: "center",
    alignItems: "center",
  },
  quantityPriceInputsNew: {
    borderWidth: 1,
    borderColor: "#79747E",
    padding: 4,
    textAlign: "center",
    borderRadius: 3,
    width: "100%",
    maxWidth: 80,
    height: "30px",
  },
  fileList: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    marginTop: 20,
    gap: 10,
  },
  filePreviewSection: {
    // width: "40%", // Adjusted for a smaller layout
    // aspectRatio: 1.2, // More compact aspect ratio
    // marginBottom: 15,
    // borderRadius: 8,
    // backgroundColor: "#ffffff",
    // shadowColor: "#000",
    // shadowOpacity: 0.1,
    // shadowRadius: 6,
    // elevation: 2,
    // borderWidth: 1,
    // borderColor: "#ddd",
    // overflow: "hidden",
  },
  filePreviewHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 8,
    paddingVertical: 5,
    backgroundColor: "#f8f8f8",
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
  },
  fileNameText: {
    fontSize: 12, // Reduced font size
    fontWeight: "400",
    color: "#333",
    flexWrap: "wrap",
    maxWidth: "80%", // Prevents text overflow
  },
  fileLink: {
    fontSize: 12, // Adjusted font size
    color: "#007bff",
    textDecorationLine: "underline",
    textAlign: "center",
    marginTop: 5,
  },
  previewContainer: {
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer", // Ensures a pointer cursor on hover (works on web)
  },
  imagePreview: {
    width: "100%",
    height: 80, // Reduced height for image preview
    backgroundColor: "#f5f5f5",
    objectFit: "cover", // Ensure image covers the area without stretching
    borderTopWidth: 1,
    borderTopColor: "#eee",
  },
  cardImage: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    objectFit: "cover", // Ensure the image fits the container
  },
  placeholderIconContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e0e0e0",
    width: "100%",
    height: 80, // Same height for placeholder as the image preview
  },
  pdfLink: {
    fontSize: 12,
    color: "#007aff",
    textDecorationLine: "underline",
    textAlign: "center",
    marginVertical: 3,
  },
});

export default OrderScreenSecond;
