import React, { memo, useCallback, useEffect, useState } from "react";
import {
  View,
  Button,
  TouchableOpacity,
  StyleSheet,
  Modal,
  Dimensions,
  Alert,
  Platform,
  Image,
} from "react-native";
import * as DocumentPicker from "expo-document-picker";
import { getFileFromS3, pushFileToS3 } from "../../../services/s3BucketService";
import {
  addDeliveryAttachment,
  getDeliveryAttachment,
  updateDeliveryAttachment,
} from "../../../services/deliveryService";
import FilePreview from "../../Operations/utils/FilePreview";
import { ReadOnlyProps } from "../../../utils/templates";
import { BlurView } from "expo-blur";
import { IconButton, Text } from "react-native-paper";
import Spinner from "../../../components/shared/Spinner";
import { useToast } from "react-native-toast-notifications";
import * as ImagePicker from "expo-image-picker";
import { TextInput } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/Ionicons";

interface UploadAttachmentDeliveryContentProps {
  selectedItem: any;
  setModalVisible: (visible: boolean) => void;
}

const UploadAttachmentDeliveryContent: React.FC<UploadAttachmentDeliveryContentProps> =
  memo(({ selectedItem, setModalVisible }) => {
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isUploaded, setIsUploaded] = useState<boolean>(false);
    const [deliveryAttachment, setDeliveryAttachment] = useState<any>(null);
    const [file, setFile] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToast();
    const [docNum] = useState(selectedItem?.DocNum);
    const [remarks, setRemarks] = useState<string>("");

    useEffect(() => {
      let isMounted = true;

      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await getDeliveryAttachment(docNum);
          if (response?.data?.status) {
            setDeliveryAttachment(response.data.data);
            const attachment = response.data.data.attachment;
            if (attachment) {
              const fileData = await getFileFromS3(attachment);
              if (fileData?.imageUrl) {
                setFile({
                  fileName: attachment,
                  fileUri: fileData.imageUrl,
                  fileType: attachment.substring(
                    attachment.lastIndexOf(".") + 1
                  ),
                });
              }
              if (response.data.data?.remarks) {
                setRemarks(response.data.data.remarks);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching delivery attachment:", error);
        } finally {
          setLoading(false);
        }
      };

      if (isMounted && docNum) {
        fetchData();
      }

      return () => {
        isMounted = false;
      };
    }, [docNum]);

    const handleSubmit = useCallback(async () => {
      try {
        if (file) {
          setLoading(true);
          let uniqueFileName = "";
          if (
            deliveryAttachment &&
            file &&
            file.fileName != deliveryAttachment.attachment
          ) {
            // update with new file scenario
            setIsUploading(true);
            const uniqueId = new Date().getTime();
            uniqueFileName = uniqueId + file.fileName;
            const s3UploadResponse = await pushFileToS3(
              "POST",
              file.fileUri,
              uniqueFileName,
              file.fileType
            );
            if (s3UploadResponse) {
              setIsUploading(false);
            }
          } else if (!deliveryAttachment) {
            // new file scenario for addition
            setIsUploading(true);
            const uniqueId = new Date().getTime();
            uniqueFileName = uniqueId + file.fileName;
            const s3UploadResponse = await pushFileToS3(
              "POST",
              file.fileUri,
              uniqueFileName,
              file.fileType
            );
            if (s3UploadResponse) {
              setIsUploading(false);
            }
          } else if (
            deliveryAttachment &&
            file &&
            file.fileName == deliveryAttachment.attachment
          ) {
            uniqueFileName = deliveryAttachment.attachment;
          }

          if (!deliveryAttachment) {
            await addDeliveryAttachment(
              selectedItem?.DocNum,
              uniqueFileName,
              remarks
            );
          } else {
            await updateDeliveryAttachment(
              selectedItem?.DocNum,
              uniqueFileName,
              remarks
            );
          }
        }

        toast.show("Submission Successful", { type: "success" });
        setModalVisible(false);
      } catch (error) {
        console.error("Error submitting data: ", error);
        toast.show("Submission Failed", { type: "danger" });
      } finally {
        setIsUploading(false);
        setLoading(false);
      }
    }, [
      file,
      deliveryAttachment,
      selectedItem,
      remarks,
      setModalVisible,
      toast,
    ]);

    /* NEWER METHOD - WITH CAPTURE - WEB + NATIVE APPS */
    const selectImage = async () => {
      if (Platform.OS === "web") {
        // Use input file for web
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.capture = "environment";
        input.onchange = async (event) => {
          const fileObj = event?.target?.files[0];
          if (fileObj) {
            const reader = new FileReader();
            reader.readAsDataURL(fileObj); // Convert file to Base64

            reader.onloadend = async () => {
              const base64 = reader.result as string; // Base64 string
              await uploadImage(fileObj.name, base64, fileObj.type);
            };
          } else {
            toast.show("No image selected", { type: "danger" });
          }
        };
        input.click();
      } else {
        // Mobile (Android/iOS) - Use ImagePicker
        Alert.alert(
          "Choose Image",
          "Select an image from gallery or capture using camera",
          [
            {
              text: "Camera",
              onPress: async () => {
                const { status } =
                  await ImagePicker.requestCameraPermissionsAsync();
                if (status !== "granted") {
                  toast.show("Camera permission required", { type: "danger" });
                  return;
                }

                const result = await ImagePicker.launchCameraAsync({
                  mediaTypes: ImagePicker.MediaTypeOptions.Images,
                  allowsEditing: true,
                  quality: 1,
                });

                if (!result.canceled) {
                  const { uri } = result.assets[0];
                  await uploadImage("captured_image.jpg", uri, "image/jpeg");
                } else {
                  toast.show("Camera canceled", { type: "warning" });
                }
              },
            },
            {
              text: "Gallery",
              onPress: async () => {
                try {
                  const result = await DocumentPicker.getDocumentAsync({
                    type: "image/*",
                    copyToCacheDirectory: true,
                  });

                  if (
                    !result.canceled &&
                    result.assets &&
                    result.assets.length > 0
                  ) {
                    const { name, uri, mimeType } = result.assets[0];
                    await uploadImage(name, uri, mimeType);
                  } else {
                    toast.show("No Image Selected", { type: "danger" });
                  }
                } catch (error) {
                  console.error("Error selecting file: ", error);
                  toast.show("Error selecting file", { type: "danger" });
                }
              },
            },
            { text: "Cancel", style: "cancel" },
          ]
        );
      }
    };

    const uploadImage = async (name: any, uri: string, mimeType: any) => {
      try {
        setFile({ fileName: name, fileUri: uri, fileType: mimeType });
      } catch (error) {
        console.error("Error fetching file: ", error);
        toast.show("Fetching File Failed", { type: "danger" });
      }
    };

    return (
      <>
        {loading ? (
          <Spinner size="small" color="red" styles={{ alignSelf: "center" }} />
        ) : (
          <View style={styles.modalContent}>
            <TouchableOpacity onPress={selectImage} style={styles.selectButton}>
              <Button
                title={isUploading ? "Uploading..." : "Select Image"}
                onPress={selectImage}
              />
              {!file && <Icon name="image-outline" size={200} color="gray" />}
            </TouchableOpacity>

            {file && (
              <View>
                <FilePreview
                  key={1}
                  fileName={file.fileName}
                  fileUri={file.fileUri}
                  fileType={file.fileType}
                  styles={styles}
                  mode="preview"
                  hideIcons={true}
                  showName={false}
                />
              </View>
            )}
            <TextInput
              style={styles.input}
              placeholder="Enter remarks..."
              value={remarks}
              onChangeText={setRemarks}
            />
            <Button title="Submit" onPress={handleSubmit} />
          </View>
        )}
      </>
    );
  });

const ModalContainer: React.FC<ReadOnlyProps<Props>> = memo(
  ({ childern, modalVisible, setModalVisible }) => {
    const Children = childern;

    return (
      <View>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => setModalVisible(!modalVisible)}
        >
          <BlurView intensity={5} style={styles.centeredView}>
            <View
              style={[styles.modalView, { minWidth: "80%", minHeight: "80%" }]}
            >
              {Children && <Children />}
              <IconButton
                style={styles.button}
                icon="close"
                color={"#2196F3"}
                size={20}
                onPress={() => setModalVisible(!modalVisible)}
              />
            </View>
          </BlurView>
        </Modal>
      </View>
    );
  }
);

interface UploadAttachmentDeliveryModalProps {
  selectedItem: any;
  deliveryAttachmentModalVisible: boolean;
  setDeliveryAttachmentModalVisible: (visible: boolean) => void;
}

export const UploadAttachmentDeliveryModal: React.FC<UploadAttachmentDeliveryModalProps> =
  memo(
    ({
      selectedItem,
      deliveryAttachmentModalVisible,
      setDeliveryAttachmentModalVisible,
    }) => {
      return (
        <ModalContainer
          modalVisible={deliveryAttachmentModalVisible}
          setModalVisible={setDeliveryAttachmentModalVisible}
          childern={() => (
            <UploadAttachmentDeliveryContent
              selectedItem={selectedItem}
              setModalVisible={setDeliveryAttachmentModalVisible}
            />
          )}
        />
      );
    }
  );

interface Props {
  childern: React.ElementType;
  modalVisible?: boolean;
  isMobileDevice?: boolean;
  setModalVisible: (event: any) => void;
}

// const ModalContainer: React.FC<ReadOnlyProps<Props>> = (
//   props: ReadOnlyProps<Props>
// ) => {
//   const Children = props.childern;

//   return (
//     <View>
//       <Modal
//         animationType="slide"
//         transparent={true}
//         visible={props.modalVisible}
//         onRequestClose={() => {
//           props.setModalVisible(!props.modalVisible);
//         }}
//       >
//         <BlurView intensity={5} style={styles.centeredView}>
//           <View
//             style={[
//               styles.modalView,
//               {
//                 minWidth: "80%",
//                 minHeight: "80%",
//                 maxHeight: "80%",
//               },
//             ]}
//           >
//             {props.childern && <Children {...props} />}
//             <IconButton
//               style={styles.button}
//               icon="close"
//               color={"#2196F3"}
//               size={20}
//               onPress={() => props.setModalVisible(!props.modalVisible)}
//             />
//           </View>
//         </BlurView>
//       </Modal>
//     </View>
//   );
// };

const { width } = Dimensions.get("window");

// Styles
const styles = StyleSheet.create({
  modalContent: {
    backgroundColor: "white",
    padding: 20,
    width: "80%",
    alignItems: "center",
    elevation: 5,
  },
  title: {
    fontSize: 18,
    marginBottom: 20,
    fontWeight: "bold",
    color: "#333",
  },
  input: {
    height: 50,
    width: width * 0.9,
    maxWidth: 400,
    borderColor: "#4A90E2",
    borderWidth: 2,
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 12,
    fontSize: 16,
    marginTop: 15,
    marginBottom: 15,
    backgroundColor: "#F4F4F4",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 2,
  },
  imagePreview: {
    width: width * 0.9, // 90% of the screen width
    height: width * 0.9, // Maintain square aspect ratio
    maxWidth: 400, // Prevents it from getting too large
    maxHeight: 400,
    marginBottom: 20,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  noImageText: {
    marginBottom: 20,
    color: "#777",
  },
  selectButton: {
    marginBottom: 20,
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 20,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  button: {
    marginTop: 10,
    minWidth: "1%",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
});
