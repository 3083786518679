import { getUserInfo } from "./usersService";
import sapiConfig from "../configs/sapi";
import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";
import { CustomUserClaims, UserClaims } from "@okta/okta-auth-js";
import { ReportsDispatcher } from "../redux/dispatcher/reportsDispatcher";
import { actionTypesReports } from "../redux/constants/actionTypesReports";
import {
  productionStockModel,
  rawMaterialStockModel,
  rawMaterialStockOverviewModel,
  stockModel,
} from "../models/reportsModel";
import { sortCollection } from "../utils/sortCollection";

import * as Sentry from "@sentry/browser";

//BASE_URL
const BASE_URL = sapiConfig.api.domain;
const Controller = "/report";

export const getStocks = async (
  team: string,
  location: string = "No Region",
  tableHeaders: Record<string, Record<string, string | number | boolean>>
) => {
  const userInfo: UserClaims<CustomUserClaims> = await getUserInfo();

  const userLocation = location.replace("Australia/", "");
  const encodeName = encodeURIComponent(
    `${userInfo.given_name} ${userInfo.family_name}`.trim()
  );
  const userTeam = team.toLowerCase();
  //Extra permission to allow the Sale Manager to access to the standard stock report (once off requirement)
  const salesManager = encodeName == "Alex%20Tse" ? true : false;
  const apiPath =
    (userTeam === "" || userTeam === "sales") && !salesManager
      ? `${Controller}/sales_stock_report?${encodeName}`
      : ["warehouse", "external"].includes(userTeam)
      ? `${Controller}/warehouse_stock_report?${userLocation}`
      : `${Controller}/stock_report`;

  let stocksReport: Array<stockModel> = [];
  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: apiPath,
  })
    .then((result) => {
      stocksReport = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data.recordset
      );
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
    
  ReportsDispatcher(actionTypesReports.ListOfStockReport, stocksReport);
};

export const searchStocks = async (
  searchText: string,
  team: string,
  location: string
) => {
  const userInfo = await getUserInfo();

  const userLocation = location.replace("Australia/", "");
  const encodeName = encodeURIComponent(
    `${userInfo.given_name} ${userInfo.family_name}`.trim()
  );
  const userTeam = team.toLowerCase();
  const apiPath =
    userTeam === "sales" || userTeam === ""
      ? `${Controller}/sales_stock_report/search?${encodeName}&`
      : userTeam === "warehouse" || userTeam === "external"
      ? `${Controller}/warehouse_stock_report/search?${userLocation}&`
      : `${Controller}/stock_report/search?`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: `${apiPath}${searchText}`,
  })
    .then((result) => {
      return {
        status: "ACTIVE",
        isLoading: false,
        stocks: result.data.recordset,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const getQldProductionStocks = async (
  searchText: string,
  tableHeaders: Record<string, Record<string, string | number | boolean>>
) => {
  let prodStockReportList: Array<productionStockModel> = [];
  const methodPath = `${Controller}/qld_production_stock_report?${searchText}`;

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      prodStockReportList = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data.recordset
      );
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  ReportsDispatcher(
    actionTypesReports.ListOfProductionStockReport,
    prodStockReportList
  );
};

export const getRawMaterialProductionStocks = async (
  tableHeaders: Record<string, Record<string, string | number | boolean>>
) => {
  let rawMaterialStockReportList: Array<rawMaterialStockModel> = [];
  const methodPath = `${Controller}/raw_material_stock_report`;

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      rawMaterialStockReportList = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data.recordset
      );
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  ReportsDispatcher(
    actionTypesReports.ListOfRawMaterialStockReport,
    rawMaterialStockReportList
  );
};

export const getRawMaterialProductionStocksOverview = async (
  tableHeaders: Record<string, Record<string, string | number | boolean>>
) => {
  const methodPath = `${Controller}/raw_material_stock_overview_report`;
  let rawMaterialOverviewStockReportList: Array<rawMaterialStockOverviewModel> =
    [];

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      rawMaterialOverviewStockReportList = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data.recordset
      );
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  ReportsDispatcher(
    actionTypesReports.ListOfRawMaterialOverviewStockReport,
    rawMaterialOverviewStockReportList
  );
};
