import React from "react";
import {
  View,
  Text,
  Image,
  Linking,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons"; // Icon library for remove button
import * as FileSystemRefs from "expo-file-system";
import { httpMStepsClient } from "../../../services/utils/httpService";

const FilePreview = ({
  fileName,
  fileUri,
  fileType,
  styles,
  onRemove,
  mode,
  hideIcons = false,
  showName = true,
}: any) => {
  if (!fileName || !fileUri) {
    return null; // Don't render anything if no file is selected
  }

  const isImage = (fileType: string) => {
    // Normalize and handle extensions as well as MIME types
    const imageFileTypes = [
      "image/",
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "webp",
    ];
    return imageFileTypes.some((type) =>
      fileType?.toLowerCase().includes(type)
    );
  };

  const openFile = () => {
    Linking.openURL(fileUri).catch((err) =>
      console.error("Failed to open file: ", err)
    );
  };

  const downloadFile = async () => {
    try {
      if (Platform.OS === "web") {
        // Web: Create a Blob and trigger a download
        const response = await httpMStepsClient({
          baseURL: fileUri,
          type: "GET",
          path: "",
        });
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Failed to download file: ", error);
    }
  };

  return (
    <View style={styles.filePreviewSection}>
      {/* File Preview Header with Remove Icon */}
      <View style={styles.filePreviewHeader}>
        {showName && <Text style={styles.fileNameText}>{fileName}</Text>}
        {mode !== "preview" && (
          <TouchableOpacity onPress={onRemove}>
            <MaterialIcons name="close" size={18} color="red" />
          </TouchableOpacity>
        )}
        {!hideIcons && (
          <TouchableOpacity onPress={downloadFile} style={styles.iconButton}>
            <MaterialIcons name="file-download" size={18} color="blue" />
          </TouchableOpacity>
        )}
      </View>

      {/* Wrap Image or File Icon in TouchableOpacity */}
      <TouchableOpacity onPress={openFile} style={styles.previewContainer}>
        {fileType && isImage(fileType) ? (
          <Image
            source={{ uri: fileUri }}
            style={styles.imagePreview}
            resizeMode="cover" // Ensures the image is displayed as cover, maintaining aspect ratio
          />
        ) : (
          <View style={styles.placeholderIconContainer}>
            <MaterialIcons name="insert-drive-file" size={30} color="#ccc" />
          </View>
        )}
      </TouchableOpacity>
    </View>
  );
};

// const cardStyles = StyleSheet.create({});

export default FilePreview;
