import sapiConfig from "../configs/sapi";
import mapiConfig from "../configs/mapi";
import { getUserInfo } from "./usersService";
import { CustomUserClaims, UserClaims } from "@okta/okta-auth-js";
import { deliveryModel } from "../models/reportsModel";
import {
  actionTypesDelivery,
  actionTypesStockTransfer,
} from "../redux/constants/actionTypesDelivery";
import { actionTypesItems } from "../redux/constants/actionTypesItems";
import {
  DeliveryDispatcher,
  StockTransferDispatcher,
} from "../redux/dispatcher/deliveryDispatcher";
import { ItemsDispatcher } from "../redux/dispatcher/itemsDispatcher";
import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";
import { sortCollection } from "../utils/sortCollection";

import * as Sentry from "@sentry/browser";

//BASE_URL
const BASE_URL = sapiConfig.api.domain;
const MAPI_URL = mapiConfig.api.domain;

const getLocation = (location: string) => {
  return location.replace("Australia/", "");
};

export const getDelivery = async (
  type: string,
  searchText: string,
  team: string,
  location: string,
  endpoint: string,
  tableHeaders: Record<string, Record<string, string | number | boolean>>
) => {
  let DeliveryResult: Array<deliveryModel> = [];
  const userInfo: UserClaims<CustomUserClaims> = await getUserInfo();
  const encodeName = encodeURIComponent(
    `${userInfo.given_name} ${userInfo.family_name}`.trim()
  );
  const methodPath = `/report/${endpoint}?${getLocation(
    location
  )}&${team.toLowerCase()}&${searchText}&${encodeName}`;

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      DeliveryResult = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data.recordset
      ) as Array<deliveryModel>;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
  type == "DeliveryReport"
    ? DeliveryDispatcher(actionTypesDelivery.ListOfDelivery, DeliveryResult)
    : StockTransferDispatcher(
        actionTypesStockTransfer.ListOfStockTransfer,
        DeliveryResult
      );
  type == "DeliveryReport"
    ? ItemsDispatcher(
        actionTypesItems.ListOfNestedDeliveryItems,
        DeliveryResult
      )
    : ItemsDispatcher(
        actionTypesItems.ListOfNestedStockTransferItemsList,
        DeliveryResult
      );
};

export const getDeliveryAttachment = async (DocNum: string) => {
  const methodPath = `/delivery/${DocNum}`;

  return await httpMStepsClient({
    baseURL: MAPI_URL,
    type: "GET",
    path: methodPath,
  });
};

export const addDeliveryAttachment = async (
  DocNum: string,
  fileName: string,
  remarks: string
) => {
  const methodPath = `/delivery/add`;

  await httpMStepsClient({
    baseURL: MAPI_URL,
    type: "POST",
    path: methodPath,
    body: {
      DocNum: DocNum,
      attachment: {
        fileName: fileName,
      },
      remarks: remarks,
    },
  })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const updateDeliveryAttachment = async (
  DocNum: string,
  fileName: string,
  remarks: string
) => {
  const methodPath = `/delivery/update`;

  await httpMStepsClient({
    baseURL: MAPI_URL,
    type: "PUT",
    path: methodPath,
    body: {
      DocNum: DocNum,
      attachment: {
        fileName: fileName,
      },
      remarks: remarks,
    },
  })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};
