import React, { useContext, useEffect, useState } from "react";
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import OrderScreenFirst from "./partials/OrderScreenFirst";
import OrderScreenSecond from "./partials/OrderScreenSecond";
import OrderScreenThird from "./partials/OrderScreenThird";
import OrderScreenFourth from "./partials/OrderScreenFourth";
import DeviceOrientationContext from "../../components/context/DeviceOrientationContext";
import { useForm } from "react-hook-form";
import { operationsState } from "../../redux/state/operations";
import {
  cloneOperationOrder,
  manageOperation,
} from "../../services/operationService";
import { useToast } from "react-native-toast-notifications";
import { useIsFocused } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { processAttachments } from "../../services/s3BucketService";
import { getStocks } from "../../services/stockService";
import { headersStock } from "../Reports/utils/stockHeaders";
import { userClaimInformation } from "../../models/usersModel";
import Spinner from "../../components/shared/Spinner";

export default function PlaceOrderScreen({ navigation, route }: any) {
  const toast = useToast();
  const isFocused = useIsFocused();
  const [currentScreen, setCurrentScreen] = useState(1);
  const [approved, setApproved] = useState(false);
  const { isMobileDevice, isSmallDevice } = useContext(
    DeviceOrientationContext
  );
  const tab2Color = currentScreen >= 2 ? "#0DF124" : "#D1D1D1";
  const tab3Color = currentScreen == 3 ? "#62FF7B" : "#EAE8E8";
  const [confirmationType, setConfirmationType] = useState("");
  const navigateBack = () => {
    if (currentScreen == 1) navigation.goBack();
    else setCurrentScreen((screen) => screen - 1);
  };
  const contentStyle = {
    width: isSmallDevice ? "100%" : "60%",
    maxWidth: isSmallDevice ? "100%" : "60%",
    height: "100%",
  };
  const shippingMethod = operationsState.shippingMethodList;
  const orderTypeList = operationsState.orderTypeList;
  const locations = operationsState.locationsList;
  const [componentHeight, setComponentHeight] = useState(0);
  const { titleToDisplay, operation, mode, requestType } = route.params;
  const defaultValues = {
    customerName: "",
    customerReference: "",
    customerNumber: "",
    customerEmail: "",
    deliveryDate: new Date(),
    shippingMethod: "",
    address: "",
    location: "",
    products: [],
    freightDelivery: 0,
    receiptId: "",
    remarks: "",
    dateCreated: new Date(),
    orderStatus: "Pending",
    orderType: "New Order",
    attachments: [],
  };
  const handleComponentLayout = (event: any) => {
    const { height } = event.nativeEvent.layout;
    setComponentHeight(height);
  };
  const form = useForm({
    defaultValues: defaultValues,
  });
  const { setValue, reset, trigger, getValues, setError, clearErrors } = form;
  const validateProduct = (val: any): boolean => {
    if (val.length == 0) {
      setError("products", {
        type: "manual",
        message: "At least add one product",
      });
      return false;
    }
    clearErrors("products");
    return true;
  };
  const validateOrderType = (): boolean => {
    // Check if at least one orderType key has a truthy value
    const isAnySelected = orderTypeList.some((order: any) =>
      getValues(order.key)
    );

    if (!isAnySelected && mode !== "edit" && mode != "clone") {
      setError("orderType", {
        type: "manual",
        message: "At least select one option",
      });
      return false;
    }

    // Clear errors if at least one is selected
    clearErrors("orderType");
    return true;
  };

  const validationRules = {
    customerName: {
      required: "Customer name is required",
      minLength: {
        value: 2,
        message: "Customer name should be at least 2 characters long",
      },
      maxLength: {
        value: 50,
        message: "Customer name should not exceed 50 characters",
      },
    },
    customerReference: {
      required: "Customer reference is required",
      minLength: {
        value: 3,
        message: "Customer reference should be at least 3 characters long",
      },
      maxLength: {
        value: 20,
        message: "Customer reference should not exceed 20 characters",
      },
    },
    customerNumber: {
      required: "Customer number is required",
      minLength: {
        value: 6,
        message: "Customer number should be at least 6 characters long",
      },
      maxLength: {
        value: 15,
        message: "Customer number should not exceed 15 characters",
      },
      pattern: {
        value: /^[0-9]+$/,
        message: "Customer number should contain only numbers",
      },
    },

    orderType: {
      validate: validateOrderType,
    },
    shippingMethod: {
      required: "Shipping method is required",
    },
    address: {
      required: "Address is required",
    },
    location: {
      required: "Location is required",
    },
    freightDelivery: {
      required: "FreightDelivery is required",
      min: {
        value: 1,
        message: "FreightDelivery should be greater than zero",
      },
    },
    products: {
      validate: validateProduct,
      required: "Product",
    },
  };
  const navigateForward = async (confirmation = null) => {
    if (currentScreen == 4) return;
    let fieldsToValidate: any = [];
    if (currentScreen == 1)
      fieldsToValidate = [
        "customerName",
        "customerReference",
        "customerNumber",
        "shippingMethod",
        "address",
        "orderType",
      ];
    if (currentScreen == 2) fieldsToValidate = ["location", "products"];
    // Trigger validation for specified fields
    const isValid = await trigger(fieldsToValidate);
    let areProductsValid = true;
    let isOrderTypeValid = true;
    if (currentScreen == 1) {
      isOrderTypeValid = validationRules.orderType.validate();
    }
    if (currentScreen == 2) {
      areProductsValid = validationRules.products.validate(
        getValues("products")
      );
    }
    if (isValid && areProductsValid && isOrderTypeValid) {
      if (confirmation != null) setConfirmationType(confirmation || "");
      setCurrentScreen((screen) => screen + 1);
    } else {
      // Handle validation errors
      toast.show("Please fill in the required fields correctly.", {
        type: "danger",
      });
    }
  };

  // stock report list reload
  const { userInfo } = useSelector((state: any) => state.configurations);
  const [tableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      headersStock
    );

  const { isStockReportListLoaded, stockReportList } = useSelector(
    (state: any) => state.reports
  );
  useEffect(() => {
    if (!isStockReportListLoaded) {
      const _userInfo = userInfo as userClaimInformation;
      getStocks(_userInfo.team, _userInfo.zone_info, tableHeaders);
    }
  });
  // stock report list reload complete

  useEffect(() => {
    if (isFocused && isStockReportListLoaded) {
      setApproved(false);
      setCurrentScreen(1);
      reset();
      if (mode == "edit" || mode == "clone") {
        setValue("customerName", operation.customerName ?? "");
        setValue("customerReference", operation.customerReference ?? "");
        setValue("customerNumber", operation.customerNumber ?? "");
        setValue("customerEmail", operation.customerEmail ?? "");
        setValue(
          "deliveryDate",
          operation.deliveryDate ?? new Date().toISOString()
        );
        const getSelectedOrderType =
          orderTypeList?.find((sm) => sm.value === operation.orderType)?.key ??
          null;
        const getSelectedShippingMethod =
          shippingMethod?.find((sm) => sm.value == operation.shippingMethod)
            ?.key ?? null;
        setValue("shippingMethod", getSelectedShippingMethod ?? "");
        setValue("address", operation.address ?? "");
        setValue("location", operation.location ?? "");
        if (operation.products && operation.products.length > 0) {
          const newProductsList = operation.products.map((product: any) => {
            // Find matching productValue
            const matchingProductValue = stockReportList.find((item: any) => {
              return (
                item.ItemCode[0].toLowerCase() ===
                  product.ItemCode.toLowerCase() &&
                item.Location.toLowerCase() === product.Location.toLowerCase()
              );
            });

            const productPrice = product.price ? parseFloat(product.price) : 1;
            const productQuantity = product.quantity
              ? parseFloat(product.quantity)
              : 0;

            // Merge product with the matching productValue, if it exists
            return {
              ...product,
              ...matchingProductValue, // Add properties from productValue
              totalPrice: productPrice * productQuantity,
              totalBoxes: product.uom === "Boxes" ? productQuantity : 0,
              totalPieces:
                product.uom === "Boxes"
                  ? productQuantity * product.PerCtns
                  : productQuantity,
            };
          });

          // Set updated products list
          setValue("products", newProductsList);
        }

        setValue("freightDelivery", operation.freightDelivery ?? "0");
        setValue("receiptId", operation.receiptId ?? "");
        setValue("remarks", operation.remarks ?? "");
        setValue(
          "dateCreated",
          operation.dateCreated ?? new Date().toISOString()
        );
        setValue("orderStatus", operation.orderStatus ?? "Pending");
        setValue("orderType", getSelectedOrderType ?? "New Order");

        const handleAttachments = async (operation: any) => {
          // Await the result of processAttachments to get the resolved attachments array
          const attachments = await processAttachments(
            operation.attachments ?? []
          );

          // Add custom fields like fileName, fileUri, and fileType to each attachment
          const updatedAttachments = attachments.map((attachment) => {
            return {
              ...attachment,
              fileName: attachment?.fileName, // Custom field
              fileUri: attachment?.fileUri, // Custom field
              fileType: attachment?.fileType, // Custom field
            };
          });

          // Now set the value with the updated attachments that include custom fields
          setValue("attachments", updatedAttachments);
        };

        // Call the function in your code
        handleAttachments(operation);
      } else {
        setValue(
          "orderStatus",
          requestType == "reserve"
            ? "Reserve"
            : requestType == "prepay"
            ? "Prepay"
            : "Pending"
        );
        setValue("orderType", operation.orderType);
      }
    }
  }, [isFocused, isStockReportListLoaded]);
  const onSubmit = async (form: any) => {
    const getSelectedOrderType =
      orderTypeList?.find((sm) => sm.key === getValues("orderType"))?.value ??
      null;
    const getSelectedShippingMethod =
      shippingMethod?.find((sm) => sm.key == getValues("shippingMethod"))
        ?.value ?? null;
    form.orderType = getSelectedOrderType;
    form.shippingMethod = getSelectedShippingMethod;

    setApproved(true);
    let formData = {
      documentNumber: form.customerReference,
      ...form,
    };

    if (mode === "clone") {
      formData = {
        ...formData,
        products: form?.products?.map(({ _id, ...rest }) => rest),
        orderStatus: form?.orderStatus === "Draft" ? "Draft" : "Pending",
      };
      await cloneOperationOrder(formData)
        .then((result) => {
          toast.show(result.data.message, {
            type: "success",
          });
          navigation.navigate("OperationsManagement");
        })
        .catch((err) => {
          console.log(err, "error in operations");
          setApproved(false);
          setCurrentScreen(1);
          toast.show(err.message, {
            type: "danger",
          });
        });
    } else {
      if (mode == "edit") {
        formData = {
          _id: operation._id,
          ...formData,
        };
      }
      await manageOperation(formData, mode)
        .then((result) => {
          toast.show(result.data.message, {
            type: "success",
          });
          navigation.navigate("OperationsManagement");
        })
        .catch((err) => {
          console.log(err, "error in operations");
          setApproved(false);
          setCurrentScreen(1);
          toast.show(err.message, {
            type: "danger",
          });
        });
    }
  };

  const approvedView = () => {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Text style={styles.textCenter}>Successfully Submitted</Text>
        <ActivityIndicator
          size="large"
          color="lightgrey"
          style={{ marginVertical: 5 }}
        />
        <Text style={{ fontSize: 12, color: "red", textAlign: "center" }}>
          Redirecting...
        </Text>
      </View>
    );
  };

  const ScreenNavigator = () => {
    return (
      <View style={{ flex: 1 }}>
        {approved ? (
          approvedView()
        ) : (
          <>
            {currentScreen == 1 && (
              <OrderScreenFirst
                navigateForward={navigateForward}
                mobileDevice={isSmallDevice}
                form={form}
                orderTypeList={orderTypeList}
                validationRules={validationRules}
              />
            )}
            {currentScreen == 2 && (
              <OrderScreenSecond
                navigateForward={navigateForward}
                navigateBack={navigateBack}
                mobileDevice={isSmallDevice}
                form={form}
                mode={mode}
                // locations={locations}
                validationRules={validationRules}
              />
            )}
            {currentScreen == 3 && (
              <OrderScreenThird
                navigateForward={navigateForward}
                navigateBack={navigateBack}
                mobileDevice={isSmallDevice}
                form={form}
                orderTypeList={orderTypeList}
                shippingMethod={shippingMethod}
                locations={locations}
              />
            )}
            {currentScreen == 4 && (
              <OrderScreenFourth
                navigateBack={navigateBack}
                mobileDevice={isSmallDevice}
                confirmationType={confirmationType}
                onSubmit={onSubmit}
                form={form}
              />
            )}
          </>
        )}
      </View>
    );
  };

  const TabHeader = () => {
    return (
      <View style={styles.row}>
        <View style={[styles.tab, { zIndex: 3 }]}>
          <View style={[styles.rectangle, styles.rectangle1_color]}>
            <Text style={styles.tabText}>General Info</Text>
          </View>
          <View style={[styles.triangle, styles.triangle1_color]} />
        </View>
        <View style={[styles.tab, { marginLeft: -50, zIndex: 2 }]}>
          <View style={[styles.rectangle, { backgroundColor: tab2Color }]}>
            <Text style={[styles.tabText, { marginLeft: 50 }]}>Products</Text>
          </View>
          <View style={[styles.triangle, { borderLeftColor: tab2Color }]} />
        </View>
        <View style={[styles.tab, { marginLeft: -55 }]}>
          <View style={[styles.rectangle, { backgroundColor: tab3Color }]}>
            <Text style={[styles.tabText, { marginLeft: 45 }]}>Receipt</Text>
          </View>
        </View>
      </View>
    );
  };

  const DrawerHeader = () => {
    return (
      <View style={styles.drawerHeaderStyle}>
        <View style={{ flexDirection: "row" }}>
          <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
            <TouchableOpacity onPress={navigateBack}>
              <AntDesign name="arrowleft" size={24} color="black" />
            </TouchableOpacity>
          </View>
          <View style={{ paddingLeft: 10, justifyContent: "center" }}>
            <Text style={{ fontWeight: "500" }}> {titleToDisplay} </Text>
          </View>
        </View>
      </View>
    );
  };

  return (
    <>
      {!isStockReportListLoaded ? (
        <View
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner
            size="small"
            color="red"
            styles={{
              flexDirection: "row",
              justifySelf: "center",
              alignSelf: "center",
            }}
          />
        </View>
      ) : (
        <View
          style={{ flex: 1, backgroundColor: "#FFF" }}
          onLayout={handleComponentLayout}
        >
          <ScrollView style={{ flexGrow: 1 }}>
            <View style={{ minHeight: componentHeight }}>
              <DrawerHeader />
              <View style={{ alignItems: "center", height: "100%" }}>
                <View
                  style={[
                    contentStyle,
                    (!isSmallDevice || !isMobileDevice) && {
                      borderWidth: 1,
                      borderColor: "#efefef",
                    },
                  ]}
                >
                  {currentScreen <= 3 && <TabHeader />}
                  <ScreenNavigator />
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  tab: {
    flexDirection: "row",
    alignItems: "flex-start",
    flex: 1,
  },
  row: {
    flexDirection: "row",
  },
  rectangle: {
    flex: 1,
    height: 42,
    alignContent: "center",
    justifyContent: "center",
  },
  triangle: {
    width: 0,
    height: 0,
    borderTopWidth: 21,
    borderBottomWidth: 21,
    borderLeftWidth: 40,
    borderTopColor: "transparent",
    borderBottomColor: "transparent",
    marginLeft: 0,
  },
  rectangle1_color: {
    backgroundColor: "#06CF1A",
  },
  triangle1_color: {
    borderLeftColor: "#06CF1A",
  },
  tabText: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "600",
  },
  drawerHeaderStyle: {
    top: 0,
    width: "100%",
    zIndex: 999,
    backgroundColor: "#fff",
    height: 40,
    borderBottomWidth: 2,
    borderColor: "grey",
  },
  textCenter: {
    textAlign: "center",
  },
});
