import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { Button, Card, Text } from "react-native-paper";
import * as DocumentPicker from "expo-document-picker";
import { View } from "../../components/shared/Themed";
import useColorScheme from "../../hooks/useColorScheme";
import { CustomHorizontalLine } from "../../components/custom/CustomHorizontalLine";

import * as XLSX from "xlsx";
import {
  containerMongoDbModal,
  containerSpreadSheetModal,
} from "../../models/containersModel";
import { SelectBoxControl } from "../../components/custom/SelectBoxControl";
import { TextInputControl } from "../../components/custom/TextInputControl";
import { DateInputControl } from "../../components/custom/DateInputControl";
import DeviceOrientationContext from "../../components/context/DeviceOrientationContext";

import { Flex } from "@react-native-material/core";
import Icon from "react-native-vector-icons/AntDesign";
import { addDaysToDate } from "../../utils/dateUtility";

import { useForm, Controller } from "react-hook-form";
import { useToast } from "react-native-toast-notifications";
import {
  manageContainer,
  getContainerById,
  manageBulkContainers,
} from "../../services/containersService";
import { containersState } from "../../redux/state/containers";
import { ReadOnlyProps } from "../../utils/templates";
import { useSelector } from "react-redux";
import oktaConfig from "../../configs/okta";

interface Props {
  route: any;
  navigation: any;
}

export default function ManageContainers({
  route,
  navigation,
}: ReadOnlyProps<Props>) {
  const params = route.params;
  const { userRoles } = useSelector((state: any) => state.configurations);

  const isContainerUpdated: boolean =
    params.container && Object.keys(params.container).length > 0;
  const containerRecord: containerMongoDbModal = isContainerUpdated
    ? JSON.parse(params.container)
    : {};

  const toast = useToast();
  const colorScheme = useColorScheme();

  const isUserAdmin = userRoles.some((role: string) =>
    containersState.allowedRolesForContainers.includes(role)
  );

  const { isSmallDevice } = useContext(DeviceOrientationContext);
  const [displayLoader, setDisplayLoader] = useState<boolean>(false);
  const [displayBulkLoader, setDisplayBulkLoader] = useState<boolean>(false);

  const ExcelDateToJSDate = (date: number) => {
    return new Date(Math.round((date - 25569) * 86400 * 1000));
  };

  const ProcessFile = async () => {
    const selectedFileResult = await DocumentPicker.getDocumentAsync({
      type: "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const containers: Array<any> = [];
    if (
      selectedFileResult.canceled === false &&
      selectedFileResult.assets &&
      selectedFileResult.assets.length > 0
    ) {
      const selectedFile = selectedFileResult.assets[0];
      setDisplayBulkLoader(true);
      const selectedFile_ArrayBuffer = await fetch(selectedFile.uri);
      const workbook = XLSX.read(await selectedFile_ArrayBuffer.arrayBuffer(), {
        type: "buffer",
      });
      const Sheet = workbook.Sheets["Final"];
      const SheetRows =
        XLSX.utils.sheet_to_json<containerSpreadSheetModal>(Sheet);

      SheetRows.forEach((row) => {
        const etaStore = row["ETA(Store)"] as string;
        const eta_store_end_date =
          etaStore.split("-")[1] !== undefined
            ? (etaStore.split("-")[1] as string).split("/")
            : "";
        const eta_store_end_date_format =
          eta_store_end_date !== ""
            ? `${eta_store_end_date[1]}/${eta_store_end_date[0]}/${eta_store_end_date[2]}`
            : "";

        containers.push({
          coo_number: row["Certificate of Import"],
          status: row.Type,
          container_number: row["Container No."],
          deliver_to: row["Ship Via"],
          etd: ExcelDateToJSDate(row.ETD as number),
          eta_port: ExcelDateToJSDate(row["ETA(Port)"] as number),
          eta_store:
            eta_store_end_date_format !== ""
              ? new Date(eta_store_end_date_format)
              : null,
          country_state: row.State,
          priority: row["Priority Level"],
        });
      });
      const result = await manageBulkContainers(containers);
      toast.show(result.data.message, {
        type: result.isSuccessfull ? "success" : "danger",
      });
      if (result.isSuccessfull) {
        backToList();
      }
    } else {
      toast.show("Error: couldn't retrieve the file", {
        type: "danger",
      });
    }

    setDisplayBulkLoader(false);
  };

  const backToList = () => {
    resetForm();
    setTimeout(() => {
      window.location.href = `${oktaConfig.api.redirectUri}/containers/list`;
    }, 1000);
  };

  const CardTitleWithIcon = () => {
    return (
      <>
        <Button onPress={() => backToList()} mode="text">
          <Icon name={"leftcircleo"} size={16} color="black" />
        </Button>
        &nbsp;
        <Text style={styles.cardTitleStyles}>
          {(params?.mode as string).charAt(0).toUpperCase() +
            (params?.mode as string).slice(1)}
          &nbsp;Container
        </Text>
      </>
    );
  };

  const containerDefaultValues = {
    importer: "",
    type: "",
    size: "",
    container_number: "",
    deliver_to: "",
    country_state: "",
    etd: new Date(),
    eta_port: addDaysToDate(new Date(), 14),
    eta_store: addDaysToDate(new Date(), 29),
    updated_eta: new Date(),
    dd_number: "",
    coo_number: "",
    status: "",
    remark: "",
    delay_reason: "",
    vessal_detail: "",
    priority: "",
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: containerDefaultValues,
  });

  const resetForm = () => {
    reset(containerDefaultValues);
  };

  useEffect(() => {
    if (isContainerUpdated) {
      setValue(
        "importer",
        containerRecord.importer ? (containerRecord.importer as string) : ""
      );
      setValue(
        "type",
        containerRecord.type ? (containerRecord.type as string) : ""
      );
      setValue(
        "size",
        containerRecord.size ? (containerRecord.size as string) : ""
      );
      setValue(
        "container_number",
        containerRecord.container_number ? containerRecord.container_number : ""
      );
      setValue(
        "deliver_to",
        containerRecord.deliver_to ? containerRecord.deliver_to : ""
      );
      setValue(
        "country_state",
        containerRecord.country_state
          ? (containerRecord.country_state as string)
          : ""
      );
      setValue(
        "etd",
        containerRecord.etd ? new Date(containerRecord.etd) : new Date()
      );
      setValue(
        "eta_port",
        containerRecord.eta_port
          ? new Date(containerRecord.eta_port)
          : addDaysToDate(new Date(), 14)
      );
      setValue(
        "eta_store",
        containerRecord.eta_store
          ? new Date(containerRecord.eta_store)
          : addDaysToDate(new Date(), 29)
      );
      setValue(
        "updated_eta",
        containerRecord.updated_eta
          ? new Date(containerRecord.updated_eta)
          : new Date()
      );
      setValue(
        "dd_number",
        containerRecord.dd_number ? containerRecord.dd_number : ""
      );
      setValue(
        "coo_number",
        containerRecord.coo_number ? containerRecord.coo_number : ""
      );
      setValue(
        "status",
        containerRecord.status ? (containerRecord.status as string) : ""
      );
      setValue("remark", containerRecord.remark ? containerRecord.remark : "");
      setValue(
        "delay_reason",
        containerRecord.delay_reason ? containerRecord.delay_reason : ""
      );
      setValue(
        "vessal_detail",
        containerRecord.vessal_detail ? containerRecord.vessal_detail : ""
      );
      setValue(
        "priority",
        containerRecord.priority ? (containerRecord.priority as string) : ""
      );

      //Set displayLoader to false
      setDisplayLoader(false);
    }
  }, [route.params]);

  const isContainerExists = (containerNumber: string = "") => {
    return getContainerById(containerNumber)
      .then((result) => {
        return Object.keys(result.data).length > 0;
      })
      .catch(() => {
        return false;
      });
  };

  const onSubmit = async (formData: any) => {
    setDisplayLoader(!displayLoader);

    const transactionType = params.mode === "create" ? "add" : "update";
    const containerForm =
      params.mode === "create"
        ? formData
        : {
            _id: containerRecord._id,
            ...formData,
          };

    await manageContainer(containerForm, transactionType)
      .then((result) => {
        toast.show(result.data.message, {
          type: "success",
        });

        backToList();
      })
      .catch((err) => {
        toast.show(err.message, {
          type: "danger",
        });
      })
      .finally(() => {
        setDisplayLoader(!displayLoader);
      });
  };

  return (
    <ScrollView>
      <View
        style={[
          styles.container,
          { backgroundColor: colorScheme === "light" ? "white" : "white" },
        ]}
      >
        <Card testID="containersForm" mode="elevated">
          <Card.Title title={<CardTitleWithIcon />} style={styles.cardTitle} />
          <Card.Content style={{ padding: 0 }}>
            <View style={[styles.box, { width: isSmallDevice ? 300 : 400 }]}>
              {params.mode === "create" && (
                <>
                  <View style={styles.subBox}>
                    <Button
                      icon={"file-excel-outline"}
                      mode="outlined"
                      onPress={ProcessFile}
                      color="rgb(47, 149, 220)"
                      loading={displayBulkLoader}
                      disabled={displayBulkLoader}
                    >
                      Upload Containers
                    </Button>
                    <Text style={styles.subTitle}>
                      Only .XLSX file allowed.
                    </Text>
                  </View>
                  <View style={styles.subBox}>
                    <CustomHorizontalLine />
                  </View>
                </>
              )}

              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                name="importer"
                render={({ field: { onChange, value } }) => (
                  <SelectBoxControl
                    Items={containersState.importerList}
                    defaultOption={value}
                    title="Importer"
                    width="100%"
                    placeholder="Select Importer"
                    required={false}
                    onSelectedValue={onChange}
                  />
                )}
              />
              {errors.importer && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="type"
                render={({ field: { onChange, value } }) => (
                  <SelectBoxControl
                    Items={containersState.modalityTypeList}
                    defaultOption={value}
                    title="Type"
                    width="100%"
                    placeholder="Select Type"
                    required={true}
                    onSelectedValue={onChange}
                  />
                )}
              />
              {errors.type && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                name="size"
                render={({ field: { onChange, value } }) => (
                  <SelectBoxControl
                    Items={containersState.containerSizeList}
                    defaultOption={value}
                    title="Size"
                    width="100%"
                    placeholder="Select Type"
                    required={false}
                    onSelectedValue={onChange}
                  />
                )}
              />
              {errors.size && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  validate: {
                    required: async (value) => {
                      if (!value) return "* This is required.";
                      if (params.mode === "create") {
                        if (value && (await isContainerExists(value))) {
                          return "* Container Number already exists.";
                        }
                      }
                    },
                  },
                }}
                name="container_number"
                render={({ field: { onChange, value } }) => (
                  <TextInputControl
                    title="Container Number"
                    required={true}
                    width="100%"
                    placeholder="Enter Container Number"
                    value={value}
                    setChangeText={onChange}
                    editable={params.mode === "update" ? false : true}
                  />
                )}
              />
              {errors.container_number && (
                <Text
                  style={
                    errors.container_number.message ===
                    "* Container Number is available."
                      ? styles.successColor
                      : styles.errorColor
                  }
                >
                  {errors.container_number.message}
                </Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="deliver_to"
                render={({ field: { onChange, value } }) => (
                  <TextInputControl
                    title="Delivery Location"
                    required={true}
                    width="100%"
                    placeholder="Enter Delivery Location"
                    value={value}
                    setChangeText={onChange}
                  />
                )}
              />
              {errors.deliver_to && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="country_state"
                render={({ field: { onChange, value } }) => (
                  <SelectBoxControl
                    Items={containersState.countryStateList}
                    title="State"
                    width="100%"
                    placeholder="Select State"
                    required={true}
                    defaultOption={value}
                    onSelectedValue={onChange}
                  />
                )}
              />
              {errors.country_state && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="etd"
                render={({ field: { onChange, value } }) => (
                  <DateInputControl
                    title="ETD"
                    required={true}
                    setDate={onChange}
                    value={value}
                    width="100%"
                    defaultText="Select ETD"
                    disabled={true}
                  />
                )}
              />
              {errors.etd && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="eta_port"
                render={({ field: { onChange, value } }) => (
                  <DateInputControl
                    title="ETA"
                    required={true}
                    setDate={onChange}
                    value={value}
                    width="100%"
                    defaultText="Select ETA"
                    disabled={true}
                  />
                )}
              />
              {errors.eta_port && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="eta_store"
                render={({ field: { onChange, value } }) => (
                  <DateInputControl
                    title="ETA to store"
                    required={true}
                    setDate={onChange}
                    value={value}
                    width="100%"
                    defaultText="Select ETA to store"
                    disabled={true}
                  />
                )}
              />
              {errors.eta_store && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                name="updated_eta"
                render={({ field: { onChange, value } }) => (
                  <DateInputControl
                    title="Updated ETA"
                    required={false}
                    setDate={onChange}
                    value={value}
                    width="100%"
                    defaultText="Select Updated ETA"
                    disabled={true}
                  />
                )}
              />
              {errors.updated_eta && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                name="dd_number"
                render={({ field: { onChange, value } }) => (
                  <TextInputControl
                    title="Packing List #"
                    required={false}
                    width="100%"
                    placeholder="Enter Packing List #"
                    value={value}
                    setChangeText={onChange}
                  />
                )}
              />
              {errors.dd_number && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="coo_number"
                render={({ field: { onChange, value } }) => (
                  <TextInputControl
                    title="Certificate of Origin (COO) #"
                    required={true}
                    width="100%"
                    placeholder="Enter COO #"
                    value={value}
                    setChangeText={onChange}
                  />
                )}
              />
              {errors.coo_number && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                name="status"
                render={({ field: { onChange, value } }) => (
                  <SelectBoxControl
                    Items={containersState.shipmentStatusList}
                    defaultOption={value}
                    title="Status"
                    width="100%"
                    placeholder="Select Status"
                    required={false}
                    onSelectedValue={onChange}
                  />
                )}
              />
              {errors.status && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                name="remark"
                render={({ field: { onChange, value } }) => (
                  <TextInputControl
                    title="Remarks"
                    required={false}
                    width="100%"
                    placeholder="Enter Remarks"
                    value={value}
                    setChangeText={onChange}
                  />
                )}
              />
              {errors.remark && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                name="delay_reason"
                render={({ field: { onChange, value } }) => (
                  <TextInputControl
                    title="Delay Reason"
                    required={false}
                    width="100%"
                    placeholder="Enter Delay Reason"
                    value={value}
                    setChangeText={onChange}
                  />
                )}
              />
              {errors.delay_reason && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                name="vessal_detail"
                render={({ field: { onChange, value } }) => (
                  <TextInputControl
                    title="Vessal Detail"
                    required={false}
                    width="100%"
                    placeholder="Enter Vessal Detail"
                    value={value}
                    setChangeText={onChange}
                  />
                )}
              />
              {errors.vessal_detail && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="priority"
                render={({ field: { onChange, value } }) => (
                  <SelectBoxControl
                    Items={containersState.priorityList}
                    defaultOption={value}
                    title="Priority"
                    width="100%"
                    placeholder="Select Priority"
                    required={true}
                    onSelectedValue={onChange}
                  />
                )}
              />
              {errors.priority && (
                <Text style={styles.errorColor}>* This is required.</Text>
              )}

              <Flex direction="row" justify="between">
                <View style={{ marginTop: "15px" }}>
                  <Text
                    style={{
                      textDecorationLine: "underline",
                      color: "rgb(128, 128, 128)",
                    }}
                  >
                    <Button
                      style={{ marginLeft: "-15px" }}
                      labelStyle={styles.backToButton}
                      onPress={() => backToList()}
                      color="rgb(128, 128, 128)"
                      mode="text"
                    >
                      Back to Containers
                    </Button>
                  </Text>
                </View>
                {isUserAdmin && (
                  <Button
                    mode="contained"
                    onPress={handleSubmit(onSubmit)}
                    loading={displayLoader}
                    disabled={displayLoader}
                    color="rgb(47, 149, 220)"
                    style={{ marginTop: 15 }}
                  >
                    {params?.mode}
                  </Button>
                )}
              </Flex>
            </View>
          </Card.Content>
        </Card>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  errorColor: {
    color: "red",
  },
  successColor: {
    color: "#037d50",
  },
  backToButton: {
    fontSize: 11,
  },
  cardTitle: {
    borderBottomWidth: 1,
    borderBottomColor: "rgba(216, 216, 216, 0.4)",
  },
  cardTitleStyles: {
    color: "rgb(128, 128, 128)",
    fontSize: 18,
    fontWeight: "bold",
    marginLeft: "-15px",
  },
  container: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    paddingBottom: "1%",
  },
  box: {
    flex: 1,
    flexDirection: "column",
    margin: 20,
    padding: 0,
  },
  subBox: {
    flexDirection: "column",
    padding: 0,
  },
  subTitle: {
    color: "#808080",
    fontSize: 12,
    fontWeight: "bold",
    marginTop: "1%",
  },
});
