export const headersStock = {
  Location: {
    name: "Location",
    width: 2.0,
    isSort: true,
    textAlign: "left",
    sortDirection: "asc",
  },
  ItemCode: {
    name: "Item Code",
    width: 1.5,
    textAlign: "left",
  },
  ItemName: {
    name: "Item Name",
    width: 4.0,
    textAlign: "left",
  },
  FrgnName: {
    name: "Item Description",
    width: 3.2,
    textAlign: "left",
  },
  CardName: {
    name: "Customer Name",
    width: 2.8,
    isSort: true,
    textAlign: "left",
    sortDirection: "asc",
  },
  PerCtns: {
    name: "Pcs (Carton)",
    width: 1.5,
    textAlign: "left",
  },
  PerPallet: {
    name: "Ctns (Pallet)",
    width: 1.5,
    textAlign: "left",
  },
  Available2: {
    name: "Available",
    width: 1.5,
    textAlign: "left",
  },
  AvaliablePallet: {
    name: "Available (Pallet)",
    width: 1.8,
    textAlign: "left",
  },
  OnWater: {
    name: "In Transit",
    width: 1.3,
    textAlign: "left",
  },
  OnOrder2: {
    name: "On Order",
    width: 1.3,
    textAlign: "left",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const mediumDeviceHeadersStock = {
  Location: {
    name: "Location",
    width: 2,
    isSort: true,
    textAlign: "left",
    sortDirection: "asc",
  },
  ItemName: {
    name: "Item Name",
    width: 4.0,
    textAlign: "left",
  },
  CardName: {
    name: "Customer Name",
    width: 4,
    isSort: true,
    textAlign: "left",
    sortDirection: "asc",
  },
  Available2: {
    name: "Available",
    width: 1.7,
    textAlign: "left",
  },
  AvaliablePallet: {
    name: "Available (Pallet)",
    width: 1.7,
    textAlign: "left",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const smallDeviceHeadersStock = {
  Location: {
    name: "Location",
    width: 1.2,
    isSort: true,
    textAlign: "left",
    sortDirection: "asc",
  },
  ItemName: {
    name: "Item Name",
    width: 2.3,
    textAlign: "left",
  },
  Available2: {
    name: "Available",
    width: 1.5,
    textAlign: "center",
  },

  "": { name: "", width: 0.1, textAlign: "" },
};

export const headersStockDetails = {
  Location: {
    name: "Location",
    width: 2.0,
    isSort: true,
    textAlign: "left",
    sortDirection: "asc",
  },
  ItemCode: {
    name: "Item Code",
    width: 1.8,
    textAlign: "left",
  },
  ItemName: {
    name: "Item Name",
    width: 4.0,
    textAlign: "left",
  },
  FrgnName: {
    name: "Item Description",
    width: 3.2,
    textAlign: "left",
  },
  CardName: {
    name: "Customer Name",
    width: 2.5,
    isSort: true,
    textAlign: "left",
    sortDirection: "asc",
  },
  OnHand: {
    name: "On Hand",
    width: 1.3,
    textAlign: "center",
  },
  Delivery: {
    name: "Delivery",
    width: 1.3,
    textAlign: "center",
  },
  Reserved: {
    name: "Reserved",
    width: 1.3,
    textAlign: "center",
  },
  OnWater: {
    name: "On Water",
    width: 1.3,
    textAlign: "center",
  },
  OnOrder2: {
    name: "On Order",
    width: 1.3,
    textAlign: "center",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const mediumDeviceHeadersStockDetails = {
  Location: {
    name: "Location",
    width: 2,
    isSort: true,
    textAlign: "left",
    sortDirection: "asc",
  },
  ItemName: {
    name: "Item Name",
    width: 4.0,
    textAlign: "left",
  },
  CardName: {
    name: "Customer Name",
    width: 4,
    isSort: true,
    textAlign: "left",
    sortDirection: "asc",
  },
  OnHand: {
    name: "Available",
    width: 1.7,
    textAlign: "center",
  },
  AvaliablePallet: {
    name: "Available (Pallet)",
    width: 1.7,
    textAlign: "center",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const smallDeviceHeadersStockDetails = {
  Location: {
    name: "Location",
    width: 1.2,
    isSort: true,
    textAlign: "left",
    sortDirection: "asc",
  },
  ItemName: {
    name: "Item Name",
    width: 2.3,
    textAlign: "left",
  },
  OnHand: {
    name: "Available",
    width: 1.5,
    textAlign: "center",
  },

  "": { name: "", width: 0.1, textAlign: "" },
};
