import { MAPI_URL } from "@env";

export default {
  api: {
    domain:
      process.env.NODE_ENV === "development"
        ? MAPI_URL
        : MAPI_URL,
  },
};
