import React, { useState, useEffect, useRef, useContext } from "react";
import { StyleSheet, Dimensions, ScrollView } from "react-native";
import { FAB } from "react-native-paper";
import { View } from "../../../components/shared/Themed";
import { LabelBannerWithSearchBar } from "../../../components/custom/Banners/LabelBannerWithSearchBar";
import { DataTableWithPagination } from "../../../components/custom/DataTable/DataTableWithPagination";
import { default as Modal } from "../../../components/custom/Modal";
import Spinner from "../../../components/shared/Spinner";
import useColorScheme from "../../../hooks/useColorScheme";
import { getStocks } from "../../../services/stockService";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import {
  headersStock,
  mediumDeviceHeadersStock as mdStock,
  smallDeviceHeadersStock as smStock,
} from "../utils/stockHeaders";
import { stockModel } from "../../../models/reportsModel";
import { validSignedURL } from "../../../utils/validateSignedUrl";
import { handleTableLoader } from "../../../utils/common";
import { useSelector } from "react-redux";
import { userClaimInformation } from "../../../models/usersModel";
import { useIsFocused } from "@react-navigation/native";
import { sortColumns } from "../../../utils/sortColumns";
import { filterBasedOnAnyKey } from "../../../utils/searchInArrays";
import { LabelBannerWithSearchTags } from "../../../components/custom/Banners/LabelBannerWithSearchTags";
import { StockReportDisplaySwitch } from "./utils/StockReportDisplaySwitch";
import { ReportsDispatcher } from "../../../redux/dispatcher/reportsDispatcher";
import { actionTypesReports } from "../../../redux/constants/actionTypesReports";
import { formatDate } from "../../../utils/dateUtility";

export default function StocksReportScreen({ route, navigation }: any) {
  const colorScheme = useColorScheme();
  const isFocused = useIsFocused();
  const listRef = useRef<ScrollView>(null);

  const [stocksPageLevelList, setStocksPageLevelList] = useState<
    Array<stockModel>
  >([]);

  const [isSearched, setIsSearched] = useState<boolean>(false);

  const { userInfo } = useSelector((state: any) => state.configurations);
  const {
    stockReportList,
    isStockReportListLoaded,
    stockReportFilterCategoryList,
    lastRefreshStockReport,
  } = useSelector((state: any) => state.reports);

  const [selected, setSelected] = useState<stockModel>({} as stockModel);
  const [tableHeaders, setTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      headersStock
    );
  const [searchedText, setSearchedText] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [lastRefresh, setLastRefresh] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [, setFabOpen] = useState<Record<string, boolean>>({
    open: false,
  });
  const [fabPosition] = useState<number>(320);
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [isImageLoading, setImageLoading] = useState<boolean>(true);
  const [contentOffSet, setContentOffSet] = useState<number>(0);

  const [_isDisplayViewOn, _setIsDisplayViewOn] = useState(false);
  const [_filterCategory, _setFilterCategory] = useState<string>("all");

  const { isLargeDevice, isMediumDevice, isMobileDevice, isSmallDevice } =
    useContext(DeviceOrientationContext);

  const displayTableLoader = () => {
    handleTableLoader(true, false, setIsSearched);
  };

  const fadeTableLoader = () => {
    handleTableLoader(false, true, setIsSearched);
  };

  const handleScroll = (event: any) => {
    setContentOffSet(event.nativeEvent.contentOffset.y);
  };

  const onHandleSortColumn = (columnKey: string) => {
    const _sortedStocksReportList: Array<stockModel> = sortColumns(
      stocksPageLevelList,
      columnKey,
      tableHeaders,
      setTableHeaders
    );

    setStocksPageLevelList(_sortedStocksReportList);
  };

  const handleSearch = (searchText: string) => {
    displayTableLoader();

    if (searchText.trim() !== "") {
      setSearchedText(searchText);

      const filteredListBasedOnSearchOrCondition: Array<stockModel> =
        filterBasedOnAnyKey(stockReportList, searchText);

      const _filterStockByCategory = filterStockByCategory(
        filteredListBasedOnSearchOrCondition,
        _filterCategory
      );

      //filter based on tags or display switch
      setStocksPageLevelList(
        _isDisplayViewOn
          ? displayStockByCarton(_filterStockByCategory)
          : displayStockByPiece(_filterStockByCategory)
      );
    } else {
      setSearchedText("");
    }

    fadeTableLoader();
  };

  const onFabOpenChange = ({ open }: { open: boolean }) => setFabOpen({ open });

  const filterStockByCategory = (
    _stocksReport: Array<stockModel>,
    _filterCategory: string
  ) => {
    return _stocksReport.filter((stock: stockModel) => {
      switch (_filterCategory) {
        case "produce":
          return [101, 102, 103, 104, 106].includes(stock.ItmsGrpCod);
          break;
        case "berry":
          return stock.ItmsGrpCod === 100;
          break;
        case "meat":
          return stock.ItmsGrpCod === 110;
          break;
        case "meals":
          return [111, 116].includes(stock.ItmsGrpCod);
          break;
        case "salad":
          return stock.ItmsGrpCod === 107;
          break;
        case "bakery":
          return stock.ItmsGrpCod === 108;
          break;
        case "NSW":
          return stock.State[0] === "NSW";
          break;
        case "VIC":
          return stock.State[0] === "VIC";
          break;
        case "QLD":
          return stock.State[0] === "QLD";
          break;
        case "TAS":
          return stock.State[0] === "TAS";
          break;
        case "WA":
          return stock.State[0] === "WA";
          break;
        case "SA":
          return stock.State[0] === "SA";
          break;
        default:
          return stock;
          break;
      }
    });
  };

  const displayStockByCarton = (_stocksReport: Array<stockModel>) => {
    return _stocksReport.map((stock: stockModel) => {
      const _available = Number(stock.OnHand) - stock.Quantity - stock.Delivery;
      const _availablePallet = Math.round(
        _available / stock.PerCtns / stock.PerPallet
      );
      const _availableByCarton = Number(_available) / stock.PerCtns;
      const _onWater = Number(stock.OnWater) / stock.PerCtns;
      const _onOrder = Number(stock.OnOrder2) / stock.PerCtns;
      return {
        ...stock,
        ItemCode: typeof stock.ItemCode === 'string' ? stock.ItemCode : stock.ItemCode[0],
        ItemName: typeof stock.ItemName === 'string' ? stock.ItemName : stock.ItemName[0],
        State: typeof stock.State === 'string' ? stock.State : stock.State[0],
        AvaliablePallet:
          _availablePallet < 0 ? "0" : _availablePallet.toString(),
        Available2: _availableByCarton < 0 ? "0" : _availableByCarton.toString(),
        OnWater: _onWater < 0 ? "0" : _onWater.toString(),
        OnOrder2: _onOrder < 0 ? "0" : _onOrder.toString(),
      };
    });
  };

  const displayStockByPiece = (_stocksReport: Array<stockModel>) => {
    return _stocksReport.map((stock: stockModel) => {
      const _available = Number(stock.OnHand) - stock.Quantity - stock.Delivery;
      const _availablePallet = Math.round(
        _available / stock.PerCtns / stock.PerPallet
      );
      return {
        ...stock,
        ItemCode: stock.ItemCode[0],
        ItemName: stock.ItemName[0],
        State: stock.State[0],
        AvaliablePallet:
          _availablePallet < 0 ? "0" : _availablePallet.toString(),
        Available2:
          _available  < 0 ? "0" : _available.toString(),
        OnWater:
          Number(stock.OnWater) < 0 ? "0" : Number(stock.OnWater).toString(),
        OnOrder2:
          Number(stock.OnOrder2) < 0 ? "0" : Number(stock.OnOrder2).toString(),
      };
    });
  };

  useEffect(() => {
    setImageLoading(true);
  }, [modalVisible]);

  useEffect(() => {
    setLastRefresh(lastRefreshStockReport);
  }, [lastRefreshStockReport]);

  useEffect(() => {
    let isMounted: boolean = true;
    const _userInfo = userInfo as userClaimInformation;

    if (isMounted) {
      //To restrict fetching data from endpoint, if data is available in store
      if (stocksPageLevelList.length === 0 || isRefresh) {
        displayTableLoader();

        //set filtering by category & display by carton to default
        _setIsDisplayViewOn(false);
        _setFilterCategory("all");
        setSearchedText("");

        //set last refresh date
        const formattedLastRefreshDate = formatDate(new Date(), "short", true);
        ReportsDispatcher(
          actionTypesReports.LastRefreshStockReport,
          formattedLastRefreshDate
        );

        getStocks(_userInfo.team, _userInfo.zone_info, tableHeaders);

        fadeTableLoader();
      }
    }

    setTableHeaders(
      isLargeDevice ? headersStock : isMobileDevice ? smStock : mdStock
    );

    setRefresh(false);

    return () => {
      isMounted = false;
    };
  }, [isFocused, isRefresh]);

  useEffect(() => {
    displayTableLoader();

    const _stocksReport = _isDisplayViewOn
      ? stocksPageLevelList
      : (stockReportList as Array<stockModel>);
    const filteredListBasedOnSearchOrCondition =
      searchedText.trim().length > 0
        ? filterBasedOnAnyKey(_stocksReport, searchedText)
        : _stocksReport;

    if (_isDisplayViewOn) {
      setStocksPageLevelList(
        displayStockByCarton(filteredListBasedOnSearchOrCondition)
      );
    } else {
      setStocksPageLevelList(
        filterStockByCategory(
          displayStockByPiece(filteredListBasedOnSearchOrCondition),
          _filterCategory
        )
      );
    }
    
    fadeTableLoader();
  }, [_isDisplayViewOn]);

  useEffect(() => {
    displayTableLoader();

    const _stocksReport = stockReportList as Array<stockModel>;
    const filteredListBasedOnSearchOrCondition =
      searchedText.trim().length > 0
        ? filterBasedOnAnyKey(_stocksReport, searchedText)
        : _stocksReport;

    if (_isDisplayViewOn) {
      setStocksPageLevelList(
        displayStockByCarton(
          filterStockByCategory(
            filteredListBasedOnSearchOrCondition,
            _filterCategory
          )
        )
      );
    } else {
      setStocksPageLevelList(
        displayStockByPiece(
          filterStockByCategory(
            filteredListBasedOnSearchOrCondition,
            _filterCategory
          )
        )
      );
    }
    
    fadeTableLoader();
  }, [_filterCategory]);

  useEffect(() => {
    displayTableLoader();

    const _stocksReport = stockReportList as Array<stockModel>;
    const filteredListBasedOnSearchOrCondition =
      searchedText.trim().length > 0
        ? filterBasedOnAnyKey(_stocksReport, searchedText)
        : _stocksReport;

    if (_filterCategory === "all" && !_isDisplayViewOn) {
      setStocksPageLevelList(
        displayStockByPiece(filteredListBasedOnSearchOrCondition)
      );
    }
    
    fadeTableLoader();
  }, [stockReportList, _isDisplayViewOn, _filterCategory === "all"]);

  useEffect(() => {
    validSignedURL(
      selected.ItemCode || "",
      "label",
      setImageLoading,
      setSelectedImage
    );
  }, [selected]);

  const ModalContent = () => {
    const _excludedKeys = [
      "ItemCode",
      "InPallets",
      "FrgnName",
      "CardCode",
      "CardName",
    ];

    const _orderedKeys = [
      "ItmsGrpCod",
      "ItemName",
      "Location",
      "PerCtns",
      "PerPallet",
      "Available2",
      "AvaliablePallet",
      "OnWater",
      "OnOrder2",
    ];

    const _specialLabels = {
      PerPallet: {
        oldLabel: "PerPallet",
        newLabel: "Total Per Pallet",
        newValue: (selected.PerPallet * selected.PerCtns) as unknown as string,
        supportingValue: ((selected.PerPallet * selected.PerCtns) /
          selected.PerCtns) as unknown as string,
      },
      ItmsGrpCod: {
        oldLabel: "ItmsGrpCod",
        newLabel: "Display",
        newValue: _isDisplayViewOn ? "Per CARTON" : "Per PIECE",
      },
      Available2: {
        oldLabel: "Available2",
        newLabel: "Available",    
      },
      OnOrder2: {
        oldLabel: "OnOrder2",
        newLabel: "On Order"
      },
    };

    return (
      <Modal.ModalContent
        title={selected.ItemCode || ""}
        subTitle={selected.FrgnName}
        icon="folder"
        uri={selectedImage}
        loading={isImageLoading}
        childern={
          <Modal.ModalBody
            listData={selected}
            excludedKeys={_excludedKeys}
            renameList={headersStock}
            orderedKeys={_orderedKeys}
            specialLabels={_specialLabels}
          />
        }
      />
    );
  };

  return (
    <ScrollView
      scrollEventThrottle={16}
      style={{ backgroundColor: colorScheme === "light" ? "white" : "white" }}
      onScroll={handleScroll}
      ref={listRef}
    >
      <View
        style={[
          styles.container,
          { backgroundColor: colorScheme === "light" ? "#f2f2f2" : "#f2f2f2" },
        ]}
      >
        <View
          style={[
            styles.containerContent,
            {
              width: isLargeDevice ? "100%" : isMediumDevice ? "100%" : "100%",
            },
          ]}
        >
          {isStockReportListLoaded ? (
            <View>
              {/* <FAB
                icon="arrow-up"
                style={[
                  styles.fab,
                  {
                    top:
                      contentOffSet +
                      Dimensions.get("window").height -
                      fabPosition,
                  },
                ]}
                onPress={() =>
                  listRef.current?.scrollTo({ x: 0, y: 0, animated: true })
                }
                small
              /> */}
              <LabelBannerWithSearchBar
                titleName={`Total Items: ${stocksPageLevelList.length}`}
                handleSearch={handleSearch}
                setRefresh={setRefresh}
                dataToExport={
                  stocksPageLevelList.map(({
                    ItemCode, 
                    ItemName, 
                    FrgnName,
                    PerCtns ,
                    PerPallet,
                    State,
                    Location,
                    CardName,
                    CardCode,
                    Available2,
                    OnWater,
                    OnOrder2
                  }) => ({                    
                    ItemCode, 
                    ItemName, 
                    FrgnName,
                    PerCtns ,
                    PerPallet,
                    State,
                    Location,
                    CardName,
                    CardCode,
                    Available2,
                    OnWater,
                    OnOrder2}))
                }
                fileName="stockReportScreen"
                sheetName="Stock Report Screen"
              />
              <LabelBannerWithSearchTags
                isMobileDevice={isMobileDevice}
                isSmallDevice={isSmallDevice}
                label="Quick Filter"
                filterCategoryList={stockReportFilterCategoryList}
                filterCategory={_filterCategory}
                setFilterCategory={_setFilterCategory}
                childern={
                  <StockReportDisplaySwitch
                    title="Display"
                    lastRefresh={
                      !isSmallDevice && !isMobileDevice ? lastRefresh : ""
                    }
                    isDisplayViewOn={_isDisplayViewOn}
                    setIsDisplayViewOn={_setIsDisplayViewOn}
                  />
                }
              />
              <DataTableWithPagination
                headers={tableHeaders}
                rows={stocksPageLevelList}
                itemsPerPage={20}
                useModal={true}
                isDataLoading={isSearched}
                isRowsExpandable={false}
                setModalVisible={setModalVisible}
                setSelected={setSelected}
                isDetail={true}
                handleRowClick={() => {}}
                handleDeleteRow={() => {}}
                handleSortColumn={onHandleSortColumn}
              />
            </View>
          ) : (
            <View>
              <Spinner
                size="small"
                color="red"
                styles={{ flexDirection: "row", margin: "20%" }}
              />
            </View>
          )}
        </View>
      </View>
      <Modal.ModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        childern={ModalContent}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  containerContent: {
    flexDirection: "column",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    zIndex: 1,
  },
});